import React, { useEffect } from 'react';
import moment from 'moment';
import styles from './viewform.module.scss';
import TripBreakdownComponent from '../TripBreakDown/Tripbreakdown';
import { generatePdf } from '../../services/traveldetailsservice';
import { onPdfgenerate, errorToast } from '../../utils/utilities';
import TripCalendar from '../TravelDetails/Calendar';
import { ERROR_MESSAGE } from '../../constants';

export default function TravelDetailsView({ rowsData, userDetails, customDaysClassName, tripBreakdown, travelId, tripPeriod, loading,resetDataOnTabChange, header }: any) {
  const onPdfSuccess = (response: any) => {
    onPdfgenerate(response);
    loading(false);
  }
  const onError = () => {
    loading(false);
    errorToast(ERROR_MESSAGE.DOWNLOAD_PDF);
  }
  const pdfDownload = () => {
    if (travelId) {
      loading(true);
      generatePdf(travelId, header, onPdfSuccess,onError);
    }
  };
  useEffect(() => {
    return () =>{ 
      resetDataOnTabChange();
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (

    <>
      <section className={`${styles['view-form-wrapper']} bg-fff d-flex my-4 flex-column align-items-end`}>
        <section id="pdf-view" className="w-100 px-4 py-4">
          <div>
            <div className="d-flex flex-row justify-content-between"><p className="color-4D4F5C OpenSansSemiBold size-28">Travel Dairy</p> <button className="btn btn-primary px-5 py-2 me-5 my-4" onClick={pdfDownload}>Download as pdf</button></div>
            <div className={`${styles['bg-EDF7FD']} px-4  d-flex flex-row mb-3 py-5`}>
              <div className="col-2">
                <p className="d-flex flex-column"><span className="size-14 color-4D4F5C">Name </span><span className="size-22 color-4D4F5C">{(userDetails && userDetails[0]) ? userDetails[0].TravFN : ""}</span></p>
              </div>
              <div className="col-2">
                <p className="d-flex flex-column"><span className="size-14 color-4D4F5C">Unikey </span><span className="size-22 color-4D4F5C">{(userDetails && userDetails[0]) ? userDetails[0].TravUniKey : ""}</span></p>
              </div>
              <div className="col-2">
                <p className="d-flex flex-column"><span className="size-14 color-4D4F5C">Staff/Non Staff </span><span className="size-22 color-4D4F5C">{((userDetails && userDetails[0]) && userDetails[0].isStaffChecked) ? "Non-Staff" : "Staff"}</span></p>
              </div>
              <div className="col-2">
                <p className="d-flex flex-column"><span className="size-14 color-4D4F5C"> Travel type</span><span className="size-22 color-4D4F5C">{userDetails[0].isDomInterChecked ? "International" : "Domestic"}</span></p>
              </div>
              <div className="col-2">
                <p className="d-flex flex-column"><span className="size-14 color-4D4F5C">Submission ID </span><span className="size-22 color-4D4F5C">{travelId ?? 'NA'}</span></p>
              </div>
              <div className="col-2">
                <p className="d-flex flex-column"><span className="size-14 color-4D4F5C">Travel Dates </span><span className="size-22 color-4D4F5C">{`${tripPeriod[0] && moment(tripPeriod[0]).format('MMMM DD YYYY')} - ${tripPeriod[1] && moment(tripPeriod[1]).format('MMMM DD YYYY')}`}</span></p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <h1 className="OpenSansSemiBold size-20">Trip Agenda</h1>
            <div className="legend d-flex flex-row align-items-center">
              <div className="d-flex flex-row align-items-center me-3">
                <span className="business-color legend-indicator"></span>
                <span className="ps-2">Business</span>
              </div>
              <div className="d-flex flex-row align-items-center me-3">
                <span className="private-color legend-indicator"></span>
                <span className="ps-2">Private</span>
              </div>
              <div className="d-flex flex-row align-items-center me-3">
                <span className="rest-color legend-indicator"></span>
                <span className="ps-2">Rest</span>
              </div>
              <div className="d-flex flex-row align-items-center me-3">
                <span className="travel-color legend-indicator"></span>
                <span className="ps-2">Travel</span>
              </div>
              <div className="d-flex flex-row align-items-center me-3">
                <span className="unaccounted-color legend-indicator"></span>
                <span className="ps-2">Unaccounted</span>
              </div>
            </div>
          </div>
          <div>
            <table className={`${styles.tableagenda} table-light mt-3  tbl_code_with_mark table`}>
              <thead>
                <tr> <th className="col-2">Location  </th>
                  <th ><span className="d-flex flex-row justify-content-between me-3 align-items-center">Start and End Date </span></th>
                  <th className="col-2">Purpose</th>
                  <th className="col-2">Main Activity | Leave Type</th>
                  <th className="col-2">Description</th>
                </tr>
              </thead>
              <tbody>
                {rowsData && rowsData.length > 0 && rowsData.map((data: any) => {
                  return (<tr>
                    <td className='text-align-left'>{data.Location}</td>
                    <td className='text-align-left'>{data.SED && data.SED.length > 0 && data.SED[0] && data.SED[1] && moment(data.SED[0]).format("YYYY-MM-DD")} - {data.SED && data.SED.length > 0 && data.SED[0] && data.SED[1] && moment(data.SED[1]).format("YYYY-MM-DD")}</td>
                    <td className='text-align-left'>{data.purposeStateArr && data.purposeStateArr['purpose']}</td>
                    <td className='text-align-left'>{(data.activityStateArr[0]) ? (data.activityStateArr[0]['Activityname']) : (data.activityStateArr['Activityname' as unknown as number])}</td>
                    <td className='text-align-left'>{data.Description}</td>
                  </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </section>
        <div className='w-100'>
          <h5 className='OpenSansSemiBold size-20 text-align-left m-3'>Trip Summary</h5>
          <p className='d-flex flex-row flex-wrap'>
          <TripCalendar calendarDates={customDaysClassName}/>
            { customDaysClassName && customDaysClassName.length>0 && <div className='mx-5 ps-4'><TripBreakdownComponent {...tripBreakdown} /></div>}
          </p>
        </div>

      </section>

    </>
  );
};

