import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import SubHeader from '../../components/SubHeader/SubHeader';
import Footer from '../../components/Footer/Footer';
import TravelDetails from '../../components/TravelDetails/TravelDetails';

const DefaultLayout = (props: any) => {
  const { children } = props;
  
  return (
      <section className="d-flex flex-column section-wrapper bg-E6E7E8 h-100">
        <Header userData={props.userData}/>
        <div className="wrapper">
          <SubHeader />
          {children}
          <Footer />
        </div>
      </section>
  );
};

export default DefaultLayout;
