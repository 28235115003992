import axios from 'axios';
import { BASE_URL } from '../constants'
import { onRequest, onRequestError } from './requestInterceptor';
import { onResponse, onResponseError } from './responseInterceptor';
import { useOktaAuth } from '@okta/okta-react';

export const API = axios.create({
    baseURL: BASE_URL,
    timeout: 30000
});

// API.interceptors.request.use(onRequest, onRequestError);
API.interceptors.response.use(onResponse, onResponseError);