import React, { useState, useEffect } from "react";
import Home from '../components/Home/Home'
import { BrowserRouter, Route, Switch, useHistory, withRouter } from 'react-router-dom';
import DefaultLayout from './layout/Defaultlayout';

// Authentication
// import { useMsal } from "@azure/msal-react";
// import { loginRequest } from "../config/authConfig";
// import { callMsGraph } from "../api/graph";

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback, useOktaAuth } from '@okta/okta-react';
import oktaConfig from '../config/okta-config'

interface ITravelDiaryRoutesProps {
}

const oktaAuth = new OktaAuth(oktaConfig.oidc)

const TravelDiaryRoutes: React.FunctionComponent<ITravelDiaryRoutesProps> = (props) => {
  const history = useHistory()

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    // trigger login if not authenticated
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    }
  };

  // useEffect(() => {
  //   triggerLogin()
  // }, []); // Update if authState changes

  return (
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}>
          <Switch>
            <SecureRoute path="/" exact component={Home} />
            <Route path="/login" component={LoginCallback} />
          </Switch>
      </Security>
  );
};

export default TravelDiaryRoutes;
