import React, { useState, forwardRef, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import MaterialTable from 'material-table';
import styles from './myDashboard.module.scss';
import DownloadIcon from '../../public/assets/download-icon.svg';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import DeleteIcon from '@material-ui/icons/Delete';
import { ReactComponent as Edit } from '../../public/assets/download-icon.svg';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Tab, Tabs } from 'react-bootstrap';
import { generatePdf, deleteTravelDetails, getTravelDetailsByPage } from '../../services/traveldetailsservice';
import { onPdfgenerate, errorToast } from '../../utils/utilities';
import { formatDateTime } from '../../utils/formatDate';
import { APP_URL, BASE_URL, ERROR_MESSAGE, TABLE_MESSAGES } from '../../constants';
import { setResumeTravelDetails, setCopyTravelDetails } from '../../slices/settingSlice';
import { is } from 'immer/dist/internal';
// const Edit = require('../../public/assets/edit.png') 

export default function MyDashboardView({ unikey, onCopy, header }: any) {
    const [loading, setLoading] = useState(false);
    const [tableMessage, setTableMessage] = useState(TABLE_MESSAGES.LOADING)
    const tableRef = React.useRef();
    const dispatch = useDispatch();
    const onPdfSuccess = (response: any) => {
        onPdfgenerate(response);
        setLoading(false);

    };
    const onError = () => {
        setLoading(false);
        errorToast(ERROR_MESSAGE.DOWNLOAD_PDF);
    }

    const downloadPdf = (travelId: string) => {
        if (travelId) {
            setLoading(true);
            generatePdf(travelId, header, onPdfSuccess, onError)
        }
    };
    const onDeleteSuccess = () => {
        setLoading(false);
        if (tableRef.current) {
            (tableRef.current as any).onQueryChange();
        }

    }
    const onDeleteError = (error) => {
        setLoading(false);
        errorToast(ERROR_MESSAGE.DELETE_TRAVEL_DETAILS);

    }
    const onDelete = (submissionId: string) => {
        swal({
            text: "Are you sure you want to delete the travel details",
            icon: "warning",
            buttons: { cancel: true, confirm: true },
        })
            .then((willDelete) => {
                if (willDelete) {
                    setLoading(true);
                    deleteTravelDetails(submissionId, header, onDeleteSuccess, onDeleteError)
                } else {

                }
            });

    }
    // OnResume
    const onResumeTravelDetails = (rowData) => {
        dispatch(setResumeTravelDetails({ isResume: true, details: rowData }));
        onCopy();
    };
    //On copy
    const onCopyTravelDetails = (rowData) => {
        dispatch(setCopyTravelDetails({ isCopy: true, details: rowData }));
        onCopy();
    }

    type MyDashboardStatus = "Submitted" | "Pending"
    const fetchTravelDetails = (resolve: Function, reject: Function, status: MyDashboardStatus, pageNum:number) => {
        getTravelDetailsByPage(unikey, header, status, pageNum)
            .then((response) => {
                const submittedData = response['submissions'];
                const totalCount = response['total_count'];
                // const identifyTableMessage = loading === true ? setTableMessage(TABLE_MESSAGES.LOADING) : setTableMessage(TABLE_MESSAGES.NO_RECORDS);

                resolve({
                    data: submittedData && submittedData.map((item: any) => {
                        return {
                            ...item,
                            name: `${item?.formData?.traveller?.givenName} ${item?.formData?.traveller?.surname}`,
                            submittedDate: formatDateTime(item.submissionInfo.lastUpdated, 'DD/MM/YYYY | h:mm a'),
                            travelDate: `${formatDateTime(item?.formData?.tripPeriod?.start, 'DD/MM/YYYY')} - ${formatDateTime(item?.formData?.tripPeriod?.end, 'DD/MM/YYYY')}`,
                        }
                    }),
                    page: pageNum,
                    totalCount: totalCount,
                })
            })
            .catch((error) => {
                const error_msg = error.status === 0 ? 'Https' : error.status;
                setTableMessage(`${error_msg} error: we can't fetch your travel records.`)
            })
    }

return (
    <section className={`${styles['dashboard-wrapper']} bg-fff d-flex my-4 flex-column align-items-end ms-5`}>
        <div id="dashaboard-view" className="w-100 dashboardView">
            <Tabs
                defaultActiveKey="completed"
                id="uncontrolled-tab-example"
                className="mb-3">
                <Tab eventKey="completed" title="Completed" className="completed">
                    {<MaterialTable
                        isLoading={loading}
                        title=""
                        columns={[
                            { title: 'Traveller', field: 'name' },
                            { title: 'Submitted Date', field: 'submittedDate' },
                            { title: 'Travel Date', field: 'travelDate' },
                            {
                                field: 'url',
                                title: 'Download PDF',
                                headerStyle: { textAlign: "center" },
                                cellStyle: { textAlign: "center" },
                                render: (rowData: any) => <img alt="download pdf" src={DownloadIcon} style={{ width: 27, cursor: 'pointer' }} onClick={() => downloadPdf(rowData.id)} />
                            }

                        ]}

                        data={(query) => new Promise((resolve, reject) => {
                            fetchTravelDetails(resolve, reject, 'Submitted', query.page)
                        }
                        )}

                        localization={{ body: { emptyDataSourceMessage: tableMessage } }}

                        icons={{
                            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
                            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
                            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
                        }}
                        options={{
                            search: false,
                            sorting: false,
                            draggable: false,
                            headerStyle: {
                                fontFamily: 'Open-Sans-SemiBold',
                                fontSize: '1.3rem'
                            },
                            paging: true,
                            pageSize: 20,
                            pageSizeOptions: [20],
                            paginationType: "stepped",
                            showFirstLastPageButtons: false,
                            showTitle: false,
                            toolbar: false,
                            actionsColumnIndex: -1,
                            actionsCellStyle: {
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '24px',
                                width: '100%',
                                marginBottom: '-1px'
                            }
                        }}
                        actions={[
                            {
                                icon: FileCopyOutlinedIcon,
                                tooltip: 'Copy travel details',
                                onClick: (event, rowData: any) => onCopyTravelDetails(rowData)
                            }

                        ]}
                    />}
                </Tab>

                <Tab eventKey="pending" title="Pending">
                    {<MaterialTable
                        title=""

                        tableRef={tableRef}
                        isLoading={loading}
                        columns={[
                            { title: 'Traveller', field: 'name', headerStyle: { textAlign: "left" } },
                            { title: 'Last Modified Date', field: 'submittedDate', headerStyle: { textAlign: "left" } },
                            { title: 'Travel Date', field: 'travelDate', headerStyle: { textAlign: "left" } },
                        ]}

                        data={(query) => new Promise((resolve, reject) => {
                            fetchTravelDetails(resolve, reject, 'Pending', query.page)
                        })}

                        localization={{ body: { emptyDataSourceMessage: tableMessage } }}

                        icons={{
                            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
                            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
                            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
                            // Edit: forwardRef((props, ref) => <CreateOutlinedIcon {...props} ref={ref} />)
                        }}
                        options={{
                            search: false,
                            sorting: false,
                            draggable: false,
                            headerStyle: {
                                fontFamily: 'Open-Sans-SemiBold',
                                fontSize: '1.3rem',
                                textAlign: "center"
                            },
                            paging: true,
                            pageSize: 20,
                            pageSizeOptions: [20],
                            paginationType: "stepped",
                            showFirstLastPageButtons: false,
                            showTitle: false,
                            toolbar: false,
                            actionsColumnIndex: -1,
                            actionsCellStyle: {
                                display: 'flex',
                                textAlign: 'center',
                                justifyContent: 'center',
                                padding: '24px',
                                width: '100%',
                                marginBottom: '-1px',
                            }
                        }}
                        actions={[
                            {
                                icon: () => <CreateOutlinedIcon />,
                                tooltip: 'Resume travel',
                                onClick: (event, rowData: any) => onResumeTravelDetails(rowData)
                            },
                            {
                                icon: DeleteIcon,
                                tooltip: 'Delete travel',
                                onClick: (event, rowData: any) => onDelete(rowData.id)
                            }
                        ]}

                    />}
                </Tab>
            </Tabs>
        </div>
    </section>
)
}