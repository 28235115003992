import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

import SomeOneElseIcon from '../../public/assets/someone-else-icon-white.svg'
import SomeOneElseIconGrey from '../../public/assets/someone-else-icon-grey.svg'
import MyselfIcon from '../../public/assets/myself-icon-white.svg';
import MyselfIconGrey from '../../public/assets/myself-icon-grey.svg';
import StaffMemberTooltip from '../Tooltips/StaffMemberTooltip';

export default function UserDetails({handleInputChange, handleMyselfChange, handleStaffMember, userDetails }:any) {
  return (
    <>
        <div className="form-field-wrapper px-3 pt-4 position-relative z-index9">
          <div className="mx-5 mt-3 brd-btm pb-5 mb-4 d-flex align-items-start flex-column animated fadeInRightShort go marginLeftWrapper ">
            <h1 className="OpenSansSemiBold size-20">Traveller Details</h1>
            <div className="d-flex flex-row mt-4">

              <div>
                <label className="checker">
                  <input className="checkbox for-check" name="isforChecked" checked={userDetails[0].mySelfChecked}
                    onChange={(e) => handleMyselfChange()} type="checkbox" />
                  <div className="check-bg"/>
                  <div className="d-flex flex-row justify-content-between text-toggle">
                    <div className="d-flex flex-row align-items-center ps-4 col-6">
                      <span className="d-flex"><img src={userDetails&& userDetails[0].mySelfChecked === false?MyselfIcon:MyselfIconGrey} alt="Myself Icon" /></span>
                      <span className="size-16 ps-3 col-7 text-toggle-span" style={{color:userDetails&& userDetails[0].mySelfChecked === false?"white":'#4D4F5C'}}>Travel Diary for Myself</span>
                    </div>
                    <div className="d-flex flex-row align-items-center ps-4">
                      <span className="d-flex ms-0"><img style={{width:'3rem'}} src={userDetails&& userDetails[0].mySelfChecked === true?SomeOneElseIcon:SomeOneElseIconGrey} alt="someone else Icon" /></span>
                      <span className="size-16 ps-3 col-7 text-toggle-span" style={{color:userDetails&& userDetails[0].mySelfChecked === true?"white":'#4D4F5C'}}>Travel Diary for Someone else</span>
                    </div>
                  </div><div className="checkmark">
                    <svg viewBox="0 0 100 100">
                    </svg>
                  </div>
                </label>

                <div className="d-flex flex-column me-4">
                <p className="size-16"><br />
                     {userDetails[0].mySelfChecked ? "Is " + (userDetails[0].TravFN.length < 3 ? "the traveller" : userDetails[0].TravFN) : "Are you " } travelling as a University of Sydney staff member? 
                    <i className="size-14 OpenSansSemiBold"><br />Please select "No" if you are travelling as a student or guest</i>  
                     </p>
                    <div className="d-flex flex-row align-items-center">
                      <label className="checker yes-no">
                        <input className="checkbox" name="isStaffChecked" checked={userDetails[0].isStaffChecked}
                          onChange={(e) => handleStaffMember()} type="checkbox" />
                        <div className="check-bg"></div>
                        <div className="d-flex flex-row justify-content-between text-toggle">
                          <div className="d-flex flex-row align-items-center ps-4 col-6">
                            <span className="size-16 ps-0 col-7 text-toggle-span" style={{color:userDetails&& userDetails[0].isStaffChecked === false?"white":'#4D4F5C'}}>Yes</span>
                          </div>
                          <div className="d-flex flex-row align-items-center ps-4">
                            <span className="size-16 ps-4 col-7 text-toggle-span"  style={{color:userDetails&& userDetails[0].isStaffChecked === true?"white":'#4D4F5C'}}>No</span>
                          </div>
                        </div><div className="checkmark">
                          <svg viewBox="0 0 100 100">
                          </svg>
                        </div>
                      </label>
                     <StaffMemberTooltip/>
                    </div>
                  </div>
              </div>

              <div className="col-7 ms-5 align-items-stretch">
                <div className="d-flex flex-row">
                  <div className="label-float col-6 me-4">
                    <input className="w-100" name="givenName" type="text" value={userDetails[0].TravFN}
                      onChange={(e) => handleInputChange(e)}
                      required={userDetails[0].requiredTravFN} placeholder=" " readOnly={userDetails[0].isReadOnly}/>
                    <label className="first-name">First Name</label>
                  </div>
                  <div className="label-float col-6 me-4">
                    <input type="text" placeholder=" " name="surName" value={userDetails[0].TravLN}
                      onChange={(e) => handleInputChange(e)} required={userDetails[0].requiredTravLN} className="w-100" readOnly={userDetails[0].isReadOnly}/>
                    <label className="first-name">Last Name</label>
                  </div>
                  </div>
                  <div className="d-flex flex-row">
                  <div className="label-float mt-5">
                    <input type="text" placeholder="UniKey" name="uniKey" required={!userDetails[0].isStaffChecked && userDetails[0].requiredTravUniKey} value={userDetails[0].TravUniKey} onChange={(e) => handleInputChange(e)} className="w-100" readOnly={userDetails[0].isReadOnly||(userDetails[0].mySelfChecked && userDetails[0].isStaffChecked)} />
                    <label className="UniKey">UniKey</label>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
    </>
  );
};

