/* common reusable component for trip breakdown to be used in Home page and view form */

import React from 'react';
import BusinessTooltip from '../Tooltips/BusinessTooltip';
import PrivateTooltip from '../Tooltips/PrivateTooltip';
import RestTooltip from '../Tooltips/RestDayTooltip';
import TravelTooltip from '../Tooltips/TravelDayTooltip';
function TripBreakdownComponent(props: any) {
  if (props.domIntCheck === false) {
    return (
      <div className=" d-flex flex-row align-items-center flex-wrap flex-xxl-nowrap ps-4">
        <div className="d-flex flex-row summary-block position-relative py-2 business align-items-end me-4 px-4 mb-2 mt-2 mb-xl-0">
          <div className="d-flex flex-column"><p className="color-4D4F5C size-14 mb-0">Business {props.fromHome ? <BusinessTooltip /> : ""}</p><div className="d-flex flex-row align-items-center"><span className="color-4D4F5C size-16 mb-0"><span className="number-days business-days">{Math.round(props.businessDays)}</span> Day(s)</span><div className="color-4D4F5C mx-3">|</div><div className="color-4D4F5C size-20 OpenSansSemiBold">{props.businessDaysPerc}%</div></div></div>
        </div>
        <div className="d-flex flex-row summary-block position-relative py-2 private align-items-end me-4 px-4 mt-2">
          <div className="d-flex flex-column"><p className="color-4D4F5C size-14 mb-0">Private{props.fromHome ? <PrivateTooltip /> : ""}</p><div className="d-flex flex-row align-items-center"><span className="color-4D4F5C size-16 mb-0"><span className="number-days Private-days"></span> {Math.round(props.privateDays)} Day(s)</span><div className="color-4D4F5C mx-3">|</div><div className="color-4D4F5C size-20 OpenSansSemiBold">{props.privateDaysPerc}%</div></div></div>
        </div>
        <div className="d-flex flex-row summary-block position-relative py-2 travel align-items-end me-4 px-4 mt-2">
          <div className="d-flex flex-column"><p className="color-4D4F5C size-14 mb-0">Travel{props.fromHome ? <TravelTooltip /> : ""}</p><div className="d-flex flex-row align-items-center"><span className="color-4D4F5C size-16 mb-0"><span className="number-days travel-days"></span>{Math.round(props.travelDays)} Day(s)</span><div className="color-4D4F5C mx-3">|</div><div className="color-4D4F5C size-20 OpenSansSemiBold">{props.travelDaysPerc}%</div></div></div>
        </div>
      </div>
    )
  }
  else{
    return (
      <div className=" d-flex flex-row align-items-center flex-wrap flex-xxl-nowrap ps-4">
        <div className="d-flex flex-row summary-block position-relative py-2 business align-items-end me-4 px-4 mb-2 mt-2 mb-xl-0">
          <div className="d-flex flex-column"><p className="color-4D4F5C size-14 mb-0">Business {props.fromHome ? <BusinessTooltip /> : ""}</p><div className="d-flex flex-row align-items-center"><span className="color-4D4F5C size-16 mb-0"><span className="number-days business-days">{Math.round(props.businessDays)}</span> Day(s)</span><div className="color-4D4F5C mx-3">|</div><div className="color-4D4F5C size-20 OpenSansSemiBold">{props.businessDaysPerc}%</div></div></div>
        </div>
        <div className="d-flex flex-row summary-block position-relative py-2 private align-items-end me-4 px-4 mt-2">
          <div className="d-flex flex-column"><p className="color-4D4F5C size-14 mb-0">Private{props.fromHome ? <PrivateTooltip /> : ""}</p><div className="d-flex flex-row align-items-center"><span className="color-4D4F5C size-16 mb-0"><span className="number-days Private-days"></span> {Math.round(props.privateDays)} Day(s)</span><div className="color-4D4F5C mx-3">|</div><div className="color-4D4F5C size-20 OpenSansSemiBold">{props.privateDaysPerc}%</div></div></div>
        </div>
        <div className="d-flex flex-row summary-block position-relative py-2 rest  align-items-end me-4 px-4 mt-2">
          <div className="d-flex flex-column"><p className="color-4D4F5C size-14 mb-0">Rest{props.fromHome ? <RestTooltip /> : ""}</p><div className="d-flex flex-row align-items-center"><span className="color-4D4F5C size-16 mb-0"><span className="number-days rest-days"></span>{Math.round(props.restDays)} Day(s)</span><div className="color-4D4F5C mx-3">|</div><div className="color-4D4F5C size-20 OpenSansSemiBold">{props.restDaysPerc}%</div></div></div>
        </div>
        <div className="d-flex flex-row summary-block position-relative py-2 travel align-items-end me-4 px-4 mt-2">
          <div className="d-flex flex-column"><p className="color-4D4F5C size-14 mb-0">Travel{props.fromHome ? <TravelTooltip /> : ""}</p><div className="d-flex flex-row align-items-center"><span className="color-4D4F5C size-16 mb-0"><span className="number-days travel-days"></span>{Math.round(props.travelDays)} Day(s)</span><div className="color-4D4F5C mx-3">|</div><div className="color-4D4F5C size-20 OpenSansSemiBold">{props.travelDaysPerc}%</div></div></div>
        </div>
      </div>
    )
  }
};

export default TripBreakdownComponent;