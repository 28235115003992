import { OKTA_CONFIG } from '../constants'

const CLIENT_ID = OKTA_CONFIG.CLIENT_ID
const ISSUER = OKTA_CONFIG.ISSUER
const REDIRECT_URI = `${window.location.origin}/login`
const OKTA_TESTING_DISABLEHTTPSCHECK = true
const okta_config = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  }
}
export default okta_config