import { OverlayTrigger, Popover } from 'react-bootstrap';
import IconInfo from '../../public/assets/ico-info.svg';
import { TOOLTIP_MESSAGES } from '../../constants';

export default function PurposeTooltip() {
    return (
        <OverlayTrigger
        placement="auto"
        trigger={["hover", "hover"]}
        overlay={
          <Popover>
            {/* <div id="popover-content" >
              <div className="d-flex flex-column inner-popup-content px-3 mb-0">
                <div className="d-flex flex-column my-1 px-2 py-2 my-3 businessTooltipColor mb-0">
                  <h5 className="OpenSansSemiBold color-fff size-16 mb-0">{TOOLTIP_MESSAGES.BUSINESS_DAY_HEADING}</h5>
                  <p className="color-fff size-13 mb-0">{TOOLTIP_MESSAGES.BUSINESS_DAY_DESCRIPTION}</p>

                </div>
                <div className="d-flex flex-column my-1 px-2 py-2 my-3 mb-0 privateTooltipColor">
                  <h5 className="OpenSansSemiBold color-fff size-16 mb-0">{TOOLTIP_MESSAGES.PRIVATE_DAY_HEADING}</h5>
                  <p className="color-fff size-13 mb-0">{TOOLTIP_MESSAGES.PRIVATE_DAY_DESCRIPTION}</p>

                </div>
                <div className="d-flex flex-column my-1 px-2 py-2 my-3 restTooltipColor mb-0">
                  <h5 className="OpenSansSemiBold color-fff size-16 mb-0">{TOOLTIP_MESSAGES.REST_DAY_HEADING}</h5>
                  <p className="color-fff  size-13 mb-0">{TOOLTIP_MESSAGES.REST_DAY_DESCRIPTION}</p>

                </div>
                <div className="d-flex flex-column my-1 px-2 py-2 my-3 travelTooltipColor ">
                  <h5 className="OpenSansSemiBold color-fff size-16 mb-0">{TOOLTIP_MESSAGES.TRAVEL_DAY_HEADING}</h5>
                  <p className="color-fff  size-13 mb-0">{TOOLTIP_MESSAGES.TRAVEL_DAY_DESCRIPTION}</p>
                </div>
              </div>

            </div> */}
            <div id="popover-content" >
            <div className="d-flex flex-column inner-popup-content px-3  py-3">
              <p className="color-fff size-13 mb-0"><span className=" color-fff size-16 mb-0">{TOOLTIP_MESSAGES.PURPOSE_HEADING}: </span>{TOOLTIP_MESSAGES.PURPOSE_DESCRIPTION}</p>
            </div>
          </div>
          </Popover>
        }
      >
        <span className="ps-3" role="button" data-bs-toggle="popover" data-bs-trigger="hover" tabIndex={0} ><img className="locationImage" src={IconInfo} alt="Info icon" /></span>
      </OverlayTrigger>
    )
}