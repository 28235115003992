import TravelDiaryRoutes from './routes/TravelDiaryRoutes';
import { BrowserRouter, withRouter } from 'react-router-dom';
// Authentication
// import { AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
// import { SignIn } from "./components/Authentication/SignIn";

const AppWithRouterAccess = withRouter(TravelDiaryRoutes);

function App() {

  return (
    <BrowserRouter>
      <div className="App h-100">
        <TravelDiaryRoutes />
      </div>
      </BrowserRouter>
  );
}

export default App;
