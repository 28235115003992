import React, { useEffect, useState } from 'react';
import { DragDropContext } from "react-beautiful-dnd";
import { ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../public/scss/__datepicker.scss'
import calendarIcon from '../../public/assets/ico-cal.svg';
import closeIcon from '../../public/assets/close.svg';
import Alert from 'react-bootstrap/Alert';
import { ReactComponent as PathIllustration } from '../../public/assets/path-illustration_animated (1).svg';
import WomanIllustration from '../../public/assets/woman-illustration.svg';
import TravelIllustration from '../../public/assets/travel-board-ilustration.svg';
import DeleteIcon from '../../public/assets/delete-ico.svg';
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ErrorIcon from '../../public/assets/error-icon.svg';
import SuccessIcon from '../../public/assets/success-icon.svg';
import WarningIcon from '../../public/assets/warning-icon.svg';
import styles from './tablescss/table.module.scss'
import { GetLocalDateTime, getDaysOfWeekBetweenDates, dateMapper } from '../../utils/formatDate';
import UserDetails from './userDetails';
import DomesticIcon from '../../public/assets/domestic-icon.svg';
import DomesticWhite from '../../public/assets/domestic-icon-white.svg';
import InternationalWhite from '../../public/assets/international-icon-white.svg';
import InternationalIcon from '../../public/assets/international-icon.svg';
import moment from 'moment';
import TravelDetailsView from '../ViewTravelDetails';
import { Tab, Tabs } from 'react-bootstrap';
import { addTravelDetails, updateTravelDetails } from '../../services/traveldetailsservice';
import TableRows from './TableRows';
import Loader from '../../components/Loader/Loader';
import MyDashboardView from '../MyDashboard';
import TripBreakdownComponent from '../TripBreakDown/Tripbreakdown';
import 'react-toastify/dist/ReactToastify.css';
import TripCalendar from './Calendar';
import { setResumeTravelDetails } from '../../slices/settingSlice';
import { PurposeActivityList, sampleUser } from '../../config/field';
import { LINKS, ERROR_MESSAGE, SUCCESS_MESSAGE, INFO_MESSAGE } from '../../constants';
import DomesticInternationalTooltip from '../Tooltips/DomesticInternational';
import LocationTooltip from '../Tooltips/LocationTooltip';
import DateRangeTooltip from '../Tooltips/DateRangeTooltip';
import BusinessWeekendTooltip from '../Tooltips/BusinessWeekendTooltip';
import { GraphData } from '../../models/AppModels'
import { errorToast, unikeyRegex, successToast, infoToast } from '../../utils/utilities';
import SortAscImage from '../../public/assets/sortasc.png';
import SortDescImage from '../../public/assets/sortdesc.png';
import { calDatesUtility } from '../../utils/formatDate';
import PurposeTooltip from '../Tooltips/PurposeTooltip';
import LeaveNotification from '../Alerts/AnnualLeave';
import OtherLeaveNotification from '../Alerts/OtherLeave';
import SSPNotification from '../Alerts/SSPAlert';
import { has } from 'lodash';
import PublicHolidaysTooltip from '../Tooltips/PublicHolidaysToolTip';
interface ITravelDetailsProps {
  userData: any,
  header: object
}

const TravelDetails: React.FunctionComponent<ITravelDetailsProps> = (props) => {
  const DD = PurposeActivityList;
  // const [apiUserDetails, setApiUserDetails] = useState({})
  const apiUserDetails = props.userData
  let _domesticpurposeList = DD && DD.filter(item => item.purpose !== 'Rest');

  const { isResume, resumePayload, isCopy, copyPayload } = useSelector((state: any) => state.settings);
  const [mySelfChecked, setMySelfChecked] = useState(false);
  const [isDomInterChecked, setIsDomInterChecked] = useState(false);

  const [isStaffChecked, setIsStaffChecked] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);/* eslint-disable @typescript-eslint/no-unused-vars */
  const [idCounter, setIdCounter] = React.useState(0);
  const [dateRangeFilled, setdateRangeFilled] = useState(false);
  const [DateRangeProp, setDateRangeProp] = useState<any>([]);
  const [calendarDates, setcalendarDates] = useState<any>([{}]);

  const [highlightedDates, setHighlightedDates] = useState([]);

  const [dateRange, setDateRange] = useState([null, null]);
  const [daysNumber, setdaysNumber] = useState(0);
  const [calendarDate, setCalendarDate] = useState<any>([]);/* eslint-disable @typescript-eslint/no-unused-vars */

  const [purposeStateArr, setpurposeStateArr] = useState([]);
  const [purposeStateColor, setpurposeStateColor] = useState('');/* eslint-disable @typescript-eslint/no-unused-vars */

  const [businessDays, setBusinessDays] = useState(0);
  const [privateDays, setPrivateDays] = useState(0);
  const [restDays, setRestDays] = useState(0);
  const [travelDays, setTravelDays] = useState(0);
  const [businessDaysPerc, setBusinessDaysPerc] = useState(0);
  const [privateDaysPerc, setPrivateDaysPerc] = useState(0);
  const [restDaysPerc, setRestDaysPerc] = useState(0);
  const [travelDaysPerc, setTravelDaysPerc] = useState(0);
  const [activityStateArr, setactivityStateArr] = useState<{ Activityname: string; id: number }[]>([]);
  const [purposeList, setpurposeList] = useState(_domesticpurposeList);
  const [activityList, setactivityList] = useState([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [checkBox, setcheckBox] = useState(false);/* eslint-disable @typescript-eslint/no-unused-vars */
  const [selectAll, setSelectAll] = useState(false);
  const [rowLengthCheck, setrowLengthCheck] = useState(true);/* eslint-disable @typescript-eslint/no-unused-vars */
  const [SED, setSED] = useState([]);
  const [excludedDates, setexcludedDates] = useState<any[]>([]);
  const [finalData, setFinalData] = useState<any[]>([]);
  const [refinedRowsData, setRefinedRowsData] = useState<any[]>([]);
  const [enableSubmitButton, setEnableSubmitButton] = useState(false);
  const [key, setKey] = useState('traveldetails');

  const [declarePopUp, setDeclarePopUp] = React.useState(false);
  const [declarationCheck, setDeclarationCheck] = React.useState(false);
  const [showAutoBookLeave, setShowAutoBookLeave] = useState(false);
  const [showManualLeave, setShowManualLeave] = useState(false);
  const [showSSPAlert, setShowSSPAlert] = useState(false);
  const [totalLeavesNot, setTotalLeavesNot] = useState(0);
  const [annual_LSLNotification, setAnnual_LSLNotification] = useState('');
  const [otherLeaveNotification, setOtherLeaveNotification] = useState('');
  const [travelId, setTravelId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFringeBenefit, setIsFringeBenefit] = useState(false);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [isVisited, setIsVisited] = useState(false);
  const [enableDelete, setEnableDelete] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [tdNotStaff, setTdNotStaff] = useState(false);
  const [isPrivateWeekend, setIsPrivateWeekend] = useState(false);
  const [hasPublicHoliday, setHasPublicHoliday] = useState(false);
  const locationValid = false;
  const SEDValid = false;
  const PurposeValid = false;
  const ActivityValid = false;
  const DescriptionValid = false;
  const requiredTravFN = false;
  const requiredTravLN = false;
  const requiredTravUniKey = false;
  const enableSED = true;
  const enablePurpose = true;
  const enableActivityDesc = true;
  const enableDesc = true;
  let AnnualLSLworkingDays: number = 0;
  let nobusinessDays: number = 0;
  let noprivatedays: number = 0;
  let norestdays: number = 0;
  let notraveldays: number = 0;
  const dispatch = useDispatch();
  let initialData = { id: idCounter, Location: '', locationValid: locationValid, SED: SED, Purpose: '', purposeStateArr: purposeStateArr, purposeStateColor: purposeStateColor, activityStateArr: activityStateArr, purposeList: purposeList, activityList: activityList, Activity: '', Description: '', SEDValid: SEDValid, PurposeValid: PurposeValid, ActivityValid: ActivityValid, DescriptionValid: DescriptionValid, checkBox: checkBox, enableSED: enableSED, enablePurpose: enablePurpose, enableActivityDesc: enableActivityDesc, enableDesc: enableDesc }

  const [rowsData, setRowsData] = React.useState([
    initialData
  ]);

  let AnnualSLCheck = (activity) => ((activity === 'Annual Leave') || (activity === 'Long Service Leave') || (activity === 'Flexible Time Off') || (activity === 'Leave Without Pay'))

  const calculateBusinessDays = (d1: any, d2: any) => {
    const days = d2.diff(d1, "days") + 1;
    let newDay: any = d1.toDate();

    for (let i = 0; i < days; i++) {
      const day = newDay.getDay();
      newDay = d1.add(1, "days").toDate();
      const isWeekend = ((day % 6) === 0);
      if (!isWeekend) {
        AnnualLSLworkingDays++;
      }
    }

    setTotalLeavesNot(AnnualLSLworkingDays);
  }


  let checkShowAutoBookLeave = () => {
    const AnnualLSLRowsData = rowsData.filter(row => AnnualSLCheck(row.Activity) && getDaysOfWeekBetweenDates(row.SED[0], row.SED[1]));
    const OtherLeaveRowsData = rowsData.filter(row => (row.Activity === 'Other Leave') && getDaysOfWeekBetweenDates(row.SED[0], row.SED[1]));

    if (AnnualLSLRowsData.length > 0){
      AnnualLSLRowsData.forEach(el => {
        const startDateTemp = moment(new Date(el.SED[0]));
        const endDateTemp = moment(new Date(el.SED[1]));
        calculateBusinessDays(startDateTemp, endDateTemp);
      })
      setShowAutoBookLeave(true);
    }
    else{
      setTotalLeavesNot(0);
      setShowAutoBookLeave(false);
    }

    if (OtherLeaveRowsData.length > 0){
      setShowManualLeave(true);
    } 
    else{
      setShowManualLeave(false);
    }
  }

  // Set Annual LSL notification
  const checkAnnual_LSL = () => {
    let OtherLeavesPresent = false;
    let tempOtherLeaveMSG;

    let PrivateLeaveRowsData = [];
    let OtherLeaveRowsData = [];
    
    PrivateLeaveRowsData = rowsData.filter(row => AnnualSLCheck(row.Activity));
    let selectedPrivateLeaves = PrivateLeaveRowsData.map(row => row.Activity)
    let uniqueSelectedPrivateLeaves = [...new Set(selectedPrivateLeaves)]
  
    // Join with ', ' for multiple items. '&' for last item or nothing if 1 item
    if (uniqueSelectedPrivateLeaves.length > 0) {
      let privateLeaveMSG = uniqueSelectedPrivateLeaves.slice(0, uniqueSelectedPrivateLeaves.length-1).join(', ')
      privateLeaveMSG = (uniqueSelectedPrivateLeaves.length > 1) ? privateLeaveMSG + " & " + uniqueSelectedPrivateLeaves[uniqueSelectedPrivateLeaves.length-1] : uniqueSelectedPrivateLeaves[0]
      setAnnual_LSLNotification(privateLeaveMSG);
    }

    OtherLeaveRowsData = rowsData.filter(row => row.Activity === 'Other Leave');
    
    if (OtherLeaveRowsData.length > 0) {
      OtherLeavesPresent = true;
    }

    if (OtherLeavesPresent) {
      tempOtherLeaveMSG = "Other";
      setOtherLeaveNotification(tempOtherLeaveMSG);
    }

  }

  let checkShowSSPAlert = () => {
    const OtherLeaveRowsData = rowsData.filter(row => (row.Activity === 'SSP - Special Program'));
    if (OtherLeaveRowsData.length > 0){
      setShowSSPAlert(true);
    }
    else{
      setShowSSPAlert(false);
    }
  }

  // Notification management
  const leaveNotification = () => {
    checkShowAutoBookLeave();
    calcPurposeDays();
    checkAnnual_LSL();
    checkShowSSPAlert();
  }

  const [userDetails, setUserDetails] = React.useState([
    {
      SubFN: apiUserDetails['givenName'],
      SubLN: apiUserDetails['surName'],
      SubUniKey: apiUserDetails['unikey'],
      requiredTravFN: requiredTravFN,
      requiredTravLN: requiredTravLN,
      requiredTravUniKey: requiredTravUniKey,
      TravFN: apiUserDetails['givenName'],
      isReadOnly: isReadOnly,
      TravLN: apiUserDetails['surName'],
      TravUniKey: apiUserDetails['unikey'],
      mySelfChecked: mySelfChecked,
      isStaffChecked: isStaffChecked,
      isDomInterChecked: isDomInterChecked,
      startDate: dateRange[0],
      endDate: dateRange[1],
      declarationCheck: declarationCheck
    }]
  );

  function getDatesInRange(startDate: any, endDate: any) {
    const date = new Date(startDate.getTime());
    const dates: any[] = [];
    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  const formatDate = (_date: any) => {
    let day = moment(_date).format('DD'),
      month = moment(_date).format('MM'),
      year = moment(_date).format('YYYY')
    return {
      year: Number(year), month: Number(month), day: Number(day)
    }
  }

  // Reset calendar dates with default greycolor
  const calendarDefaultColor = () => {
    if (!dateRange.includes(null)) {
      getDatesInRange(dateRange[0], dateRange[1]);
      let arrayOfSelectedDates = getDatesInRange(dateRange[0], dateRange[1]);
      if (arrayOfSelectedDates && arrayOfSelectedDates.length > 0 && arrayOfSelectedDates[0]) {
        let _selectcalendarDate = formatDate(dateRange[0]);
      }
      let arr: any = [];
      arrayOfSelectedDates.forEach((item, index) => {
        let date = {
          ...dateMapper(item),
          className: 'default-color'
        }
        arr.push(date)
      });
      setcalendarDates(arr);
    }
  }
  // Function: When Start and Date is changed any table rows outside this period is removed
  const deleteUnwantedRows = () => {
    if (!dateRange.includes(null)) {
      let tripStartDate = moment(dateRange[0]).format('DD/MM/YYYY'),
        tripEndDate = moment(dateRange[1]).format('DD/MM/YYYY');
      let idArray = [];
      let _rowsNotrequired = rowsData && rowsData.map(item => {
        let _dateRange = item.SED[0] && item.SED[1] && getDatesInRange(item.SED[0], item.SED[1]);

        if (_dateRange) {
          if (_dateRange && (moment(tripStartDate, 'DD/MM/YYYY').isAfter(moment(_dateRange[0], 'DD/MM/YYYY')) || moment(tripEndDate, 'DD/MM/YYYY').isBefore(moment(_dateRange[_dateRange.length - 1], 'DD/MM/YYYY')))) {
            idArray.push(item.id)
          }
        }
        return null;
      });
      let filterWantedRows = rowsData && rowsData.filter(item => !idArray.includes(item.id));

      if (idArray && idArray.length === 1 && rowsData.length === 1 && (idArray[0] === rowsData[0].id)) {
        resetRow();
      }
      else if (filterWantedRows.length === 0) {
        resetRow();
      }
      else {
        setRowsData(filterWantedRows);
      }
      let dateUtility = calDatesUtility(dateRange, filterWantedRows, highlightedDates);
      setcalendarDates(dateUtility.calStyle);
      setexcludedDates(dateUtility.excludedDatesArray);
    }
  }

  useEffect(() => {
    setUserDetails(userDetails.map(user => user ? ({
      ...user,
      SubFN: apiUserDetails['givenName'],
      SubLN: apiUserDetails['surName'],
      SubUniKey: apiUserDetails['unikey'],
      TravFN: apiUserDetails['givenName'],
      TravLN: apiUserDetails['surName'],
      TravUniKey: apiUserDetails['unikey'],

    }) : user))
  }, [props.userData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDateRangeProp(dateRange);
    let row = rowsData;
    if (!isVisited) {
      // logic for extending dates keeping color for calendar intact
      if (row && row.length > 0 && row[0].purposeStateColor !== "") {
        deleteUnwantedRows();  // Function: When Start and Date is changed any table rows outside this period is removed
      }
      else {
        calendarDefaultColor();
      }
    }
  }, [dateRange]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    leaveNotification()
  }, [rowsData]); // eslint-disable-line react-hooks/exhaustive-deps

  const manageActivity = (startDate, endDate) => {
    const isWeekend = checkWeekend(startDate, endDate);
    let activityList = [...purposeList[1].activity];
    let activityValid = false;

    if (isWeekend){
      if (activityList.filter(obj => obj.Activityname === 'Weekend').length == 0) {
        activityList.push({ Activityname: 'Weekend', id: '6' }); // Add weekend option
      }
      activityList = activityList.filter(activity => activity.Activityname !== "Public Holiday") // Hide holiday option
      activityValid = true;
    }
    else{
      if (activityList.filter(obj => obj.Activityname === 'Public Holiday').length == 0) {
        activityList.push({ Activityname: 'Public Holiday', id: '5' }); // Add holiday option
      }
      activityList = activityList.filter(activity => activity.Activityname !== "Weekend") // Hide weekend option
      activityValid = false;
    }
    return [activityList, activityValid]
  }

  // Function for start end date change in table rows
  const handleSEDChange = (id: number, obj: any, index: any) => {
    setSED(obj);
    setpurposeStateArr(obj.purpose);
    let currentRowDate = []

    if (!obj.includes(null)) {
      currentRowDate = rowsData.map(row => row.id === id ? ({
        ...row,
        SED: obj,
        locationValid: true,
        enablePurpose: false,
        SEDValid: false
      }) : row);
      setRowsData(currentRowDate);


      const gettempdates = getDatesInRange(obj[0], obj[1])
      let setDateValue = true;
      gettempdates.forEach((date) => {
        if (GetLocalDateTime(excludedDates[0]) === GetLocalDateTime(date)) {
          setDateValue = false;
          return false;
        }
      });

      if (setDateValue) {      
        leaveNotification();
        setexcludedDates([...excludedDates, ...getDatesInRange(obj[0], obj[1])]);

        currentRowDate = currentRowDate.map(row => {
          if (row.id === id) {
            return row.Location === '' ? {
              ...row,
              SED: obj,
              locationValid: true,
              enablePurpose: false,
            } : {
              ...row,
              SED: obj,
              locationValid: false,
              enablePurpose: false,
            }
          }
          else{
            return row
          }
        })

      }
      else {
        currentRowDate = currentRowDate.map(row => row.id === id ? ({
          ...row,
          SED: [],
        }) : row)
      }
    }
    setRowsData(currentRowDate)
    if (rowsData[index].purposeStateColor === '') {
      currentRowDate = rowsData.map(row => row.id === id ? ({
        ...row,
        PurposeValid: true,
        SED: obj,
        SEDValid: false,
        enablePurpose: false
      }) : row)
    }
    //####
    else {
      switch (rowsData[index].purposeStateColor) {
        case "Private":
          let activityList=null;
          let activityValid=null;
          if (!obj.includes(null)) {
            const activityValue = manageActivity(obj[0], obj[1]);
            activityList = activityValue[0];
            activityValid = activityValue[1];
          }
          //SET ACTIVITY LIST
          currentRowDate = rowsData.map(row => row.id === id ? ({
            ...row,
            SED: obj,
            activityList: activityList,
            ActivityValid: activityValid,
          }) : row)
          break;

        default:
          currentRowDate = rowsData.map(row => row.id === id ? ({
            ...row,
            SED: obj
          }) : row)
      }
    }
    
    let dateUtility = calDatesUtility(dateRange, currentRowDate, highlightedDates);

    setcalendarDates(dateUtility.calStyle);
    setRowsData(currentRowDate)
  };

  const handleInputChange = (event: any) => {

    switch (event.target.name) {
      case "givenName":
        if (event.target.value !== '') {
          setUserDetails(userDetails.map(user => user ? ({
            ...user,
            TravFN: event.target.value,
            requiredTravFN: false
          }) : user));
        }
        else {

          setUserDetails(userDetails.map(user => user ? ({
            ...user,
            TravFN: event.target.value,
            requiredTravFN: true
          }) : user));

        }
        break;
      case "surName":

        if (event.target.value !== '') {
          setUserDetails(userDetails.map(user => user ? ({
            ...user,
            TravLN: event.target.value,
            requiredTravLN: false
          }) : user));
        }
        else {

          setUserDetails(userDetails.map(user => user ? ({
            ...user,
            TravLN: event.target.value,
            requiredTravLN: true
          }) : user));

        }
        break;
      case "uniKey":

        if (event.target.value !== '') {
          setUserDetails(userDetails.map(user => user ? ({
            ...user,
            TravUniKey: event.target.value,
            requiredTravUniKey: false
          }) : user));
        }
        else {

          setUserDetails(userDetails.map(user => user ? ({
            ...user,
            TravUniKey: event.target.value,
            requiredTravUniKey: true
          }) : user));

        }
        break;
      default:
        setUserDetails(userDetails.map(user => user ? ({
          ...user,

          requiredTravFN: true,
          requiredTravLN: true,
          requiredTravUniKey: true
        }) : user));
    }
  };

  const handleMyselfChange = () => {
    let toggle = !mySelfChecked;
    setMySelfChecked(toggle);
    setIsStaffChecked(false);

    if (toggle) {
      setUserDetails(userDetails.map(user => user ? ({
        ...user,
        requiredTravFN: true,
        requiredTravLN: true,
        requiredTravUniKey: true,
        isReadOnly: false,
        TravFN: '',
        TravLN: '',
        TravUniKey: '',
        mySelfChecked: toggle,
        isStaffChecked: false

      }) : user));
      if (dateRange[0] && dateRange[1]) {

        setdateRangeFilled(false);
        setTdNotStaff(false);
      }
    }
    else {
      setUserDetails(userDetails.map(user => user ? ({
        ...user,
        requiredTravFN: false,
        requiredTravLN: false,
        requiredTravUniKey: false,
        isReadOnly: true,
        mySelfChecked: toggle,
        TravFN: apiUserDetails['givenName'],
        TravLN: apiUserDetails['surName'],
        TravUniKey: apiUserDetails['unikey'],
        isStaffChecked: false

      }) : user));
      if (dateRange[0] && dateRange[1] && userDetails[0]) {
        setdateRangeFilled(false);
        setTdNotStaff(false);
      }
    }
  };

  const handleStaffMember = () => {
    let toggle = !isStaffChecked;
    setIsStaffChecked(toggle);

    if (toggle) {
      setUserDetails(userDetails.map(user => user ? ({
        ...user,
        requiredTravUniKey: false,
        isStaffChecked: toggle,
        TravUniKey: user.mySelfChecked ? "N/A" : user.TravUniKey

      }) : user));
      if (dateRange[0] && dateRange[1]) {
        setdateRangeFilled(true);
        setTdNotStaff(true);
      }
    }
    else {
      setUserDetails(userDetails.map(user => user ? ({
        ...user,
        requiredTravUniKey: user.mySelfChecked ? true : false,
        isStaffChecked: toggle,
        // OKTA UNIKEY
        TravUniKey: user.mySelfChecked ? "" : user.TravUniKey
      }) : user));
      if (dateRange[0] && dateRange[1]) {
        setdateRangeFilled(false);
        setTdNotStaff(false);
      }
    }

  }
  const [tempInterData, setTempInterData] = useState(null)

  useEffect(() => {
    // Add click listener to the page when component mounts
    document.body.addEventListener('click', handleClick);

    // To prevent duplicate listeners, remove existing listener when component unmounts
    return () => {
      document.body.removeEventListener('click', handleClick);
    };
  }, [isDomInterChecked]);

  // Run functions whenever a page click is detected
  const handleClick = () => {
    updatePurposeDropdownClass();
    updateTravelPurposeLabel();
  };

  // If the trip is domestic, make the "Travel" dropdown option appear as "Travel (>5 Hours)"
  function updateTravelPurposeLabel(): void {
    let domestic = !isDomInterChecked;

    // ".purpose-dropdown-option" selects the Purpose dropdown menu
    // "div[class*='-singleValue']" selects the currently-selected Purpose
    // "div[id*='-option-']" selects every option inside the Purpose dropdown menu
    const options = document.querySelectorAll(".purpose-dropdown-option div[class*='-singleValue'], div[id*='-option-']");

    options.forEach((option: HTMLElement) => {
        if (option.textContent?.includes("Travel")) {
          option.textContent = domestic ? "Travel (>5 Hours)" : "Travel";
        }
    });
  }

  // Set the class of Purpose dropdown menus to either "domestic" or "international"
  // Used to style the dropdown options based on whether the trip is domestic or not
  function updatePurposeDropdownClass(): void {
    let domestic = !isDomInterChecked;
    let classDomestic = "domestic";
    let classInternational = "international";
    let className = domestic ? classDomestic : classInternational;

    // Select all Purpose dropdown menus
    var dropdowns = document.querySelectorAll('td.purpose-select');
  
    dropdowns.forEach(function(dropdown) {
      // Remove any existing domestic/international classes
      dropdown.classList.remove(classDomestic);
      dropdown.classList.remove(classInternational);

      // Add the new class
      dropdown.classList.add(className);
    });
  }

  const handleDomInter = () => {
    let toggle = !isDomInterChecked;
    setIsDomInterChecked(toggle);
    setUserDetails(userDetails.map(user => user ? ({
      ...user,
      isDomInterChecked: toggle
    }) : user));

    // International
    if (toggle) {
      setpurposeList(DD);
      // IF ROWS WERE DELETED
      if (tempInterData) {
        showInfoToast(INFO_MESSAGE.RETAIN_INTERNATIONAL)
        let _tempInterData = tempInterData && tempInterData.map(item => {
          return {
            ...item,
            purposeList: DD
          }
        });
        setRowsData(_tempInterData);
        let dateUtility = calDatesUtility(dateRange, _tempInterData, highlightedDates);
        setcalendarDates(dateUtility.calStyle);
        setexcludedDates(dateUtility.excludedDatesArray);
      }
      else {
        let _rowsData = rowsData && rowsData.map(item => {
          return {
            ...item,
            purposeList: DD
          }
        });
        setRowsData(_rowsData);
      }
    }
    // Domestic
    else {
      let _purposeList = DD && DD.filter(item => item.purpose !== 'Rest');
      let _rowsData = rowsData && rowsData.map(item => {
        return {
          ...item,
          purposeList: _purposeList
        }
      });
      setpurposeList(_purposeList)
      setTempInterData(_rowsData)
      const processedRowData = _rowsData.filter(row => row.purposeStateArr['purpose'] !== 'Rest')

      // If rows were removed, show Info toast
      if (processedRowData < _rowsData) { showInfoToast(INFO_MESSAGE.DOMESTIC_REMOVE) }

      // If no rows returned
      if (processedRowData.length === 0) {
        resetRow()
      }
      // If >= 1 rows returned
      else {
        setRowsData(processedRowData);
      }
      let dateUtility = calDatesUtility(dateRange, processedRowData, highlightedDates);
      setcalendarDates(dateUtility.calStyle);
      setexcludedDates(dateUtility.excludedDatesArray);
    }
  };

  const handleExcludedDate = (id: number, obj: any) => {
    let newexcludedDates = excludedDates;
    rowsData.map(function (row) {
      if ((row.id === id) && (row.SED.length > 0)) {
        let currentRowDates = getDatesInRange(row.SED[0], row.SED[1]);

        currentRowDates.forEach(function (date) {
          if (!!excludedDates.find(
            item => { return GetLocalDateTime(item) === GetLocalDateTime(date) })
          ) {
            newexcludedDates = newexcludedDates.filter(val => val.getTime() !== date.getTime())
          }

        })
        setexcludedDates(newexcludedDates);
      }
      return null
    })
  };


  const handleExcludedDateOnBlur = (id: number, obj: any) => {
    rowsData.map(function (row) {
      if (row.id === id) {
        let currentRowDates = getDatesInRange(row.SED[0], row.SED[1]);
        setexcludedDates([...excludedDates, ...currentRowDates])
      }
      return null
    })
  };

  // Check whether the selected date range falls into weekend
  const checkWeekend = (startDate, endDate) => {
    return ((startDate.getDay() === 6) || (startDate.getDay() === 0)) && ((endDate.getDay() === 6) || (endDate.getDay() === 0)) ? true : false
  }
  // Check whether the selected date range contains weekend
  const containWeekend = (startDate, endDate) => {
    const startDateNum = startDate.getDay();
    const diffDayNum = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24) + 1;
    const absEndNum = startDateNum + diffDayNum - 1;
    let weekendNum = 0
    // If sat or sun is met, weekendNum counts 1
    for (let i = startDateNum; i <= absEndNum; i++) {
      if ((i % 7 === 6) || (i % 7 === 0)) {
        weekendNum++;
      }
    }
    // const isSpanOneWeek = diffDayNum >= 7;
    // const hasWeekend = (isSpanOneWeek || (startDateNum + diffDayNum >= 6)) ? true : false;

    return weekendNum
  }

  const setPurpose = (id: number, index: number, obj: any) => {
    let hasPrivateWeekend = false
    if (obj.purpose === 'Rest' || obj.purpose === 'Travel') {
      setEnableSubmitButton(true);
    }
    setpurposeStateArr(obj.purpose);

    setactivityList(obj.activity);
    setactivityStateArr([]);
    const caldates: any[] = calendarDates;

    rowsData.map(row => {
      let allRowDates: any[] = [];
      excludedDates.forEach((item) => {
        let _date = {
          ...dateMapper(item),
          className: 'default-color'
        }
        allRowDates.push(_date)
      })

      const validSED = row.SED.length !== 0 && !row.SED.includes(null)
      if (row.id === id && validSED) {
        let currentRowDates = getDatesInRange(row.SED[0], row.SED[1])
        const date1: any = row.SED[0];
        const date2: any = row.SED[1];
        const Difference_In_Time: any = date2.getTime() - date1.getTime();
        // To calculate the no. of days between two dates
        let numberDays: any = Difference_In_Time / (1000 * 3600 * 24) + 1;
        // }
        currentRowDates.forEach((item, index) => {
          const itemDayNum = item.getDay();

          let month = moment(item).format('MM');
          let day = moment(item).format('DD');
          let year = moment(item).format('YYYY');
          const result = caldates.filter(({ day, month }) => !allRowDates.some(x => x.day === day))

          for (let i = 0; i < result.length; i++) {

            result[i].className = 'default-color'
            setcalendarDates([...caldates, result]);
          }

          for (let i = 0; i < caldates.length; i++) {
            if (caldates[i].month === Number(month) &&
              caldates[i].day === Number(day) &&
              caldates[i].year === Number(year)) {
              // If has PrivateBusiness weekend, set hasPrivateWeekend = True
              if ((itemDayNum === 6 || itemDayNum === 0) && obj.purpose === "Private") {
                caldates[i].className = "PrivateBusiness"
                hasPrivateWeekend = true
              }
              else { caldates[i].className = obj.purpose };
              break;
            }
          }
          setcalendarDates(caldates);
        })
      }
      return null
    });

    switch (obj.purpose) {
      case "Business":
        setRowsData(rowsData.map(row => row.id === id ? ({
          ...row,
          purposeStateArr: obj,
          activityStateArr: [],
          Activity: '',
          activityList: obj.activity,
          purposeStateColor: obj.purpose,
          PurposeValid: false,
          DescriptionValid: row.Description ? false : true,
          ActivityValid: true,
          locationValid: false,
          enableActivityDesc: false

        }) : row));

        break;
      //####
      case "Private":
        const StartDate = new Date(rowsData[index].SED[0]);
        const EndDate = new Date(rowsData[index].SED[1]);
        const activityValue = manageActivity(StartDate, EndDate);
        obj.activity = activityValue[0];
        obj.activityValid = activityValue[1];
        // Set Activity List
        setRowsData(rowsData.map(row => row.id === id ? ({
          ...row,
          purposeStateArr: obj,
          activityStateArr: [],
          Activity: '',
          activityList: obj.activity,
          purposeStateColor: obj.purpose,
          PurposeValid: false,
          DescriptionValid: false,
          ActivityValid: obj.activityValid,
          locationValid: false,
          enableActivityDesc: false

        }) : row));
        break;

      case "Rest":
        setRowsData(rowsData.map(row => row.id === id ? ({

          ...row,
          //Purpose: obj.purpose,
          purposeStateArr: obj,
          activityStateArr: obj.activity,
          activityList: obj.activity,
          purposeStateColor: obj.purpose,
          PurposeValid: false,

          DescriptionValid: false,
          ActivityValid: false,
          locationValid: false,
          enableActivityDesc: false

        }) : row));
        break;

      case "Travel":

        setRowsData(rowsData.map(row => row.id === id ? ({

          ...row,
          purposeStateArr: obj,

          activityStateArr: obj.activity,
          activityList: obj.activity,
          purposeStateColor: obj.purpose,
          PurposeValid: false,
          DescriptionValid: false,
          ActivityValid: false,
          locationValid: false,
          enableActivityDesc: false

        }) : row));
        break;
      default:
        setRowsData(rowsData.map(row => row.id === id ? ({

          ...row,
          purposeStateArr: obj,
          activityStateArr: [],
          Activity: '',
          activityList: obj.activity,
          purposeStateColor: obj.purpose,
          PurposeValid: false,
          SEDValid: false,
          DescriptionValid: false,
          locationValid: false,
          enableActivityDesc: false

        }) : row))
    }
  }

  const handlePurposeChange = (id: number, index: number, obj: any) => {
    // Rest logic for particular table row
    if (obj.purpose === 'Rest') {
      let currentRow = rowsData && rowsData.filter(item => item.id === id),
        currentDate = currentRow && currentRow.length > 0 && currentRow[0].SED && currentRow[0].SED.length > 0 && currentRow[0].SED[0];
      if (currentDate) {
        let previousDay = moment(currentDate).subtract(1, 'd');
        let isFirstDay = moment(dateRange[0]).subtract(1, 'd');
        if (moment(previousDay).format('DD/MM/YYYY') === moment(isFirstDay).format('DD/MM/YYYY')) {
          showToast(ERROR_MESSAGE.REST_DAY)
        }
        else {
          let mapData: any = rowsData && rowsData.filter((_item) => {
            if (_item.SED && _item.SED.length > 0 && _item.SED[1] && moment(_item.SED[1]).format('DD/MM/YYYY') === moment(previousDay).format('DD/MM/YYYY')) {
              return { ..._item }
            }
            return null
          })

          if (mapData && mapData.length > 0 && mapData[0].purposeStateArr && mapData[0].purposeStateArr.purpose !== 'Travel') {
            showToast(ERROR_MESSAGE.REST_DAY);
          }
          else {
            setPurpose(id, index, obj)
          }
        }

      }
    }
    else {
      setPurpose(id, index, obj)
    }

  };

  const handleactivityChange = (id: number, index: number, obj: any) => {
    setactivityStateArr(obj.Activityname);
    let rowElement: any = rowsData && rowsData.filter(item => item.id === id);

    const currentPurpose = rowElement[0].purposeStateArr.purpose;
    if (rowElement && rowElement.length > 0) {
      if (obj.Activityname !== '' && rowElement[0].purposeStateArr && currentPurpose !== 'Business') {
        setEnableSubmitButton(true);
      }

      // Enable Submit button if Description is populated
      if (obj.description !== "") { setEnableSubmitButton(true) }
    }
    const currentRowData = rowsData.map(row => row.id === id && obj.Activityname !== "" ? ({
      ...row,
      activityStateArr: obj,
      ActivityValid: false,
      locationValid: false,
      Activity: obj.Activityname
    }) : row)
    setRowsData(currentRowData);

    let dateUtility = calDatesUtility(dateRange, currentRowData, highlightedDates);
    setcalendarDates(dateUtility.calStyle);
    setexcludedDates(dateUtility.excludedDatesArray);
  };

  const calcPurposeDays = () => {
    setBusinessDays(0);
    setPrivateDays(0);
    setTravelDays(0);
    setRestDays(0);
    setBusinessDaysPerc(0);
    setPrivateDaysPerc(0);
    setTravelDaysPerc(0);
    setRestDaysPerc(0);
    setIsFringeBenefit(false);

    setIsPrivateWeekend(false);
    setHasPublicHoliday(false);

    setHighlightedDates([]);

    // Generate a map of all dates and their activity/purpose
    let dates = new Map();
    rowsData.forEach(row => {
      const startDate = row.SED[0];
      const endDate = row.SED[1];
      const activity = row.activityStateArr['Activityname'];
      const purpose = row.purposeStateColor;

      // Only consider fully-selected dates
      if (startDate == null || endDate == null) {
        return;
      }

      // Convert date range into individual dates
      let current = new Date(startDate);
      while (current <= endDate) {
        dates.set(current.getTime(), {purpose: purpose, activity: activity});
        current.setDate(current.getDate() + 1);
      }
    });

    let sum = {
      "Business": 0,
      "Private": 0,
      "Travel": 0,
      "Rest": 0,
      "Total": dates.size
    };

    let datesToHighlight = [];

    // Go through all stored dates; the types of days (e.g. Private and Business) will be counted
    dates.forEach(function(value, timestamp) {
      if (value.purpose == null || value.purpose.length == 0) {
        return; // Ignore dates with no purpose
      }

      let date = new Date(timestamp);

      let isBusinessWeekend = false;
      let isWeekend = date.getDay() == 6 || date.getDay() == 0; // Saturday and Sunday

      if (isWeekend) {
        let offsetFri = date.getDay() == 6 ? -1 : -2;
        let offsetMon = date.getDay() == 6 ? 2 : 1;
        
        // Retrieve the previous Friday and following Monday
        let friday = new Date(date);
        friday.setDate(friday.getDate() + offsetFri);
        
        let monday = new Date(date);
        monday.setDate(monday.getDate() + offsetMon);
        
        let purposeFriday = dates.has(friday.getTime()) ? dates.get(friday.getTime()).purpose : "";
        let purposeMonday = dates.has(monday.getTime()) ? dates.get(monday.getTime()).purpose : "";
  
        let nonPrivateFriday = purposeFriday != "Private";
        let nonPrivateMonday = purposeMonday != "Private";

        isBusinessWeekend = nonPrivateFriday && nonPrivateMonday;
      }

      if (value.purpose == "Private" && isBusinessWeekend) {
        // Weekends between a non-private Friday and non-private Monday are treated as Business
        sum["Business"] += 1;
        setIsPrivateWeekend(true);
        datesToHighlight.push(timestamp);
      } else if (value.activity == "Public Holiday") {
        sum["Business"] += 1; // Treat holidays as business
        setHasPublicHoliday(true);
      } else if (value.activity == "Weekend") {
        sum["Business"] += 1; // Treat weekends as business
      } else {
        sum[value.purpose] += 1;
      }
    });

    setBusinessDays(sum["Business"]);
    setPrivateDays(sum["Private"]);
    setTravelDays(sum["Travel"]);
    setRestDays(sum["Rest"]);

    let privatePercentage = getPercentage(sum["Private"], sum["Total"]);
    setIsFringeBenefit(privatePercentage >= 30);

    setBusinessDaysPerc(getPercentage(sum["Business"], sum["Total"]));
    setPrivateDaysPerc(privatePercentage);
    setTravelDaysPerc(getPercentage(sum["Travel"], sum["Total"]));
    setRestDaysPerc(getPercentage(sum["Rest"], sum["Total"]));

    setHighlightedDates(datesToHighlight);
  }

  function getPercentage(value: number, total: number) {
    let percentage = Math.floor(value / total * 100);
    return isNaN(percentage) ? 0 : percentage;
  }

  // Add table rows
  const addTableRows = (index: number) => {
    let _purposeList = DD && DD.filter(item => item.purpose !== 'Rest');
    setIdCounter(idCounter + 1);
    const rowsInput = {
      id: idCounter + 1,
      Location: '',
      SED: calendarDate,
      Purpose: '',
      activityStateArr: [],
      purposeStateArr: purposeStateArr,
      purposeStateColor: purposeStateColor,
      Activity: '',
      purposeList: isDomInterChecked ? DD : _purposeList,
      activityList: [],
      Description: '',
      locationValid: locationValid,
      SEDValid: SEDValid,
      PurposeValid: PurposeValid,
      ActivityValid: ActivityValid,
      DescriptionValid: DescriptionValid,
      checkBox: checkBox,
      enableSED: enableSED,
      enablePurpose: enablePurpose,
      enableActivityDesc: enableActivityDesc,
      enableDesc: enableDesc
    }
    const rows = [...rowsData];
    rows.splice(index + 1, 0, rowsInput);
    // setrowCount(rows);
    setRowsData(rows);

  }
  // Reset table rows
  const resetRow = () => {
    let _purposeList = DD && DD.filter(item => item.purpose !== 'Rest');
    calendarDefaultColor();
    setRowsData([{ id: 0, Location: '', locationValid: locationValid, SED: [], Purpose: '', purposeStateArr: [], purposeStateColor: purposeStateColor, activityStateArr: [], purposeList: isDomInterChecked ? DD : _purposeList, activityList: [], Activity: '', Description: '', SEDValid: SEDValid, PurposeValid: PurposeValid, ActivityValid: ActivityValid, DescriptionValid: DescriptionValid, checkBox: checkBox, enableSED: enableSED, enablePurpose: enablePurpose, enableActivityDesc: enableActivityDesc, enableDesc: enableDesc }]);
    setSelectAll(false);
    setexcludedDates([]);
    setEnableSubmitButton(false);
    setIsFringeBenefit(false);
  }
  // Delete individual table rows
  const deleteTableRows = (index: number) => {
    if (rowsData.length === 1) {
      resetRow();
    }
    else {
      const rows = [...rowsData];
      let newexcludedDates = excludedDates;
      if (rows[index].SED.length > 0) {
        let currentRowDates = rows[index].SED[0] && rows[index].SED[1] ? getDatesInRange(rows[index].SED[0], rows[index].SED[1]) : [];
        let object = {};
        let currrowdate: any[] = [];
        currentRowDates && currentRowDates.forEach(function (date) {
          if (!!excludedDates.find(
            item => { return GetLocalDateTime(item) === GetLocalDateTime(date) })
          ) {
            newexcludedDates = newexcludedDates.filter(val => val.getTime() !== date.getTime())
          }

          let month = moment(date).format('MM'); //months from 1-12
          let day = moment(date).format('DD');
          let year = moment(date).format('YYYY');
          let className = 'default-color'


          object = {
            year: Number(year), month: Number(month), day: Number(day), className: className
          }
          currrowdate.push(object)
        })

        const caldates: any[] = calendarDates;
        const result = caldates.filter(({ day, month }) => currrowdate.some(x => x.day === day));
        for (let i = 0; i < result.length; i++) {

          result[i].className = 'default-color'
          //  break;

          setcalendarDates([...caldates, result]);
        }
        setexcludedDates(newexcludedDates);
      }

      rows.splice(index, 1);
      // setexcludedDates(newexcludedDates);
      setRowsData(rows);
    }

  }

  const handleChange = (index: number, event: any) => {
    setRowsData(rowsData.map(row => row.id === index ? ({
      ...row,
      SED: calendarDate,
      [event.target.name]: event.target.value,
    }) : row));

  };
  // Logic for location change
  const handleLocationChange = (id: number, index: number, event: any) => {
    const rows = [...rowsData];
    if (rows[index].Location.length !== 0) {
      setRowsData(rows.map(row => row.id === id ? ({

        ...row,

        [event.target.name]: event.target.value,
        locationValid: event.target.value !== '' ? false : true,
        SEDValid: row.SED && row.SED.length > 0 ? false : true,
        enableSED: false

      }) : row))

    }

    else {
      setRowsData(rows.map(row => row.id === id ? ({

        ...row,

        [event.target.name]: event.target.value,
        locationValid: true,
        SEDValid: row.SED && row.SED.length > 0 ? false : true,
        enableSED: true

      }) : row))
    }

  };
  // Logic for description change
  const handleDescriptionChange = (id: number, index: number, event: any) => {
    if (rowsData[index].purposeStateColor === "Business" && event.target.value === "") {
      setEnableSubmitButton(false);
      setRowsData(rowsData.map(row => row.id === id ? ({
        ...row,
        [event.target.name]: event.target.value,
        DescriptionValid: true,
        locationValid: false

      }) : row));
    } else {
      if (rowsData[index].purposeStateColor !== "") setEnableSubmitButton(true)
      setRowsData(rowsData.map(row => row.id === id ? ({
        ...row,
        [event.target.name]: event.target.value,
        DescriptionValid: false,
        locationValid: false

      }) : row));
    }
  };
  // Logic for checkbox
  const handleCheckbox = (id: number, index: number, event: any) => {
    //setcheckBox(event.target.checked);
    let _rowData = rowsData.map(row => row.id === id ? ({
      ...row,
      checkBox: !row.checkBox

    }) : row);
    setRowsData(_rowData);
    let isChecked = _rowData && _rowData.filter(row => row.checkBox);
    if (isChecked && isChecked.length > 0) {
      setEnableDelete(true);
    }
    else {
      setEnableDelete(false);
    }
    if (selectAll) {
      setSelectAll(false);
    }

  }
  // Logic for delete
  const handleDeleteAll = () => {

    if (selectAll) {
      resetRow();
    }
    else {

      let filterUnCheckedRows = rowsData.filter((row) => !row.checkBox);

      if (filterUnCheckedRows.length === 0) {
        resetRow();
        // setRowsData([{ id: 0, Location: '', locationValid: locationValid, SED: [], Purpose: '', purposeStateArr: purposeStateArr, purposeStateColor: purposeStateColor, activityStateArr: [], purposeList: purposeList, activityList: [], Activity: '', Description: '', SEDValid: SEDValid, PurposeValid: PurposeValid, ActivityValid: ActivityValid, DescriptionValid: DescriptionValid, checkBox: checkBox, enableSED: enableSED, enablePurpose: enablePurpose, enableActivityDesc: enableActivityDesc, enableDesc: enableDesc }]);
      }
      else {
        let filteredItem = filterUnCheckedRows && filterUnCheckedRows.map((item, index) => {
          return {
            ...item,
            id: index
          }
        })
        setRowsData(filteredItem);
        let excludedArr = [];
        let dates = filteredItem && filteredItem.map((items) => {
          let dateRange = getDatesInRange(items.SED[0], items.SED[1]);
          let tripDates = dateRange && dateRange.map(ele => {
            excludedArr.push(ele);
            let mapper = dateMapper(ele)
            return {
              ...mapper
            }
          });
          return tripDates
        });
        let _flatDates = dates.flat();

        let calDates = calendarDates;
        let newDates = calDates && calDates.map(_item => {

          let isPresent = _flatDates && _flatDates.length > 0 && _flatDates.filter(_ele => (_ele.month === _item.month) && (_ele.year === _item.year) && (_ele.day === _item.day))
          return {
            ..._item,
            className: isPresent && isPresent.length > 0 ? _item.className : 'default-color'
          }

        });
        setcalendarDates(newDates);
        setexcludedDates(excludedArr)
      }
    }
    setEnableDelete(false);
  }
  // Function for checkbox 
  const handleSelectAll = () => {
    let toggle = !selectAll;
    setSelectAll(toggle);

    rowsData.forEach(el => {
      setRowsData(rowsData.map(row => row.id > -1 ? ({
        ...row,
        checkBox: toggle

      }) : row));

    });
  }
  // error toast
  const showToast = (message: string) => {
    errorToast(message);
  }

  const showInfoToast = (message: string) => {
    infoToast(message);
  }

  // Logic for submit
  const handleValidation = () => {
    let tempRowCheck = true;
    let userDetailsCheck = true;
    let dateArr: any = [];
    setShowSuccessAlert(false);
    const rows = [...rowsData];
    rowsData.forEach(el => {
      dateArr.push(moment(el.SED[0]).format("YYYY-MM-DD"));
      dateArr.push(moment(el.SED[1]).format("YYYY-MM-DD"));
      // if (!el.PurposeValid && !el.SEDValid && (!el.locationValid && el.Location !== '') && !el.DescriptionValid && !el.ActivityValid) {

      if (!el.PurposeValid && !el.SEDValid && (el.Location !== '') && !el.DescriptionValid && !el.ActivityValid) {
        // tempRowCheck = 1;
        setrowLengthCheck(tempRowCheck);
      } else {
        (setRowsData(rows.map(row => row.id === el.id ? ({
          ...row,
          locationValid: true
        }) : row)));

        tempRowCheck = false;
        setrowLengthCheck(tempRowCheck);
        // exit;
      }

    });
    let datesInRange = getDatesInRange(dateRange[0], dateRange[1]);
    let formattedDate = datesInRange.map(item => moment(item).format('MM-DD-YYYY')),
      formatedExcludedDate = excludedDates.map(item => moment(item).format('MM-DD-YYYY'))
    // let datesInRange = getDatesInRange(row.SED[0], row.SED[1]);
    let notSelectedDate = formattedDate && formattedDate.filter(item => {
      return !(formatedExcludedDate.includes(item))
    });

    if (!userDetails[0].requiredTravFN && !userDetails[0].requiredTravLN && !userDetails[0].requiredTravUniKey) {
      //userDetailsCheck = 1;
    }
    else {
      userDetailsCheck = false;
    }

    if (tempRowCheck && userDetailsCheck && notSelectedDate.length === 0) {
      handleSave(false, true);
    }

    else {
      const payload = getPayload();
      let uniKey = payload && payload[0].formData && payload[0].formData.submitter && payload[0].formData.submitter.unikey;
      let travellerUnikey = payload && payload[0].formData && payload[0].formData.traveller && payload[0].formData.traveller.unikey;
      let { givenName, surname } = payload[0].formData.traveller;
      let { travelAsStaff } = payload[0].formData;
      let isValidUnikey = checkIsUnikeyValid(travelAsStaff, travellerUnikey);
      if (uniKey === '' || (travellerUnikey === '' && travelAsStaff) || givenName === '' || surname === '') {
        showToast(ERROR_MESSAGE.TRAVELLER_UNIKEY)
      }
      else if (!isValidUnikey) {
        showToast(ERROR_MESSAGE.UNIKEY_ERROR);
      }
      else if (notSelectedDate.length > 0) {
        let month = moment(notSelectedDate[0]).format('MM');
        let element = document.getElementById(`calender-${month}`);
        if (element) {
          element.scrollIntoView();
        }
        let errorMessage = `Please select the remaining ${notSelectedDate.length} days before submitting`
        showToast(errorMessage);

      }
      else {
        showToast(ERROR_MESSAGE.REQUIRED_FIELDS)
      }
      handleSave(true);
    }
  };


  const handleCreateTD = () => {
    setLoading(true);
    let UpdatedData = finalData;
    setFinalData(UpdatedData);
    setDeclarePopUp(false);
    let finalPayload = {
      ...UpdatedData[0],
      "formData": {
        ...UpdatedData[0].formData,
        "status": "Submitted"
      }
    };
    if (travelId) {
      updateTravelDetails(travelId, finalPayload, props.header, onUpdateSuccess, onUpdateError)
    }

  }
  const onUpdateSuccess = () => {
    setLoading(false);
    setKey('viewForm');
    setIsStatusChanged(true);

  }
  const handleDragEnd = (e: any) => {
    if (!e.destination) return;
    let tempData = Array.from(rowsData);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    //setrowCount(tempData);
    setRowsData(tempData);
  };

  const sortAsc = () => {
    const rows = [...rowsData];
    let sortedRows = rows.filter(date => date.SED[0])
      .sort((a, b) => {
        let dateA: Date = new Date(a.SED[0]);
        let dateB: Date = new Date(b.SED[0])
        return dateA.getTime() - dateB.getTime()
      });
    // let sortedRows = rows.sort((a, b) => (a.SED[0] && a.SED[0]) - (b.SED[0]&&b.SED[0]));

    setRowsData(sortedRows);
  }
  const sortDsc = () => {
    const rows = [...rowsData];

    // let sortedRows = rows.sort((a, b) => (b.SED[0]&&b.SED[0]) - (a.SED[0]&&a.SED[0]));
    let sortedRows = rows.filter(date => {
      return date.SED && date.SED[0]
    })
      .sort((a, b) => {
        let dateA: Date = new Date(a.SED[0]);
        let dateB: Date = new Date(b.SED[0])
        return dateB.getTime() - dateA.getTime()
      })
    setRowsData(sortedRows);
  }
  // Declaration logic
  const handleDeclaration = () => {
    let toggle = !declarationCheck;
    setDeclarationCheck(toggle);
    setUserDetails(userDetails.map(user => user ? ({
      ...user,
      declarationCheck: toggle

    }) : user));
  }



  const [startDate, endDate] = dateRange;
  // setDateRangeProp(dateRange);

  // const [dateRangeFilled, setdateRangeFilled] = useState(false);
  const [inputFocus, setinputFocus] = useState(false);

  const labelTop = () => {
    !dateRange.includes(null) ? setinputFocus(true) : setinputFocus(!inputFocus)

  }

  const diffTwoDates = (startDate, endDate) => {
    // ((startDate !== null) && (endDate !== null)) ? setdateRangeFilled(false) : setdateRangeFilled(true)
    if ((startDate !== null) && (endDate !== null)) {
      if (userDetails[0] && userDetails[0].isStaffChecked) {
        // Condition for not showing table
        setdateRangeFilled(true);
        setTdNotStaff(true);
        // handleSave(false)
        // setShowSuccessAlert(true)
      }
      else {
        setdateRangeFilled(false);
        setTdNotStaff(false);
      }
    }
    else {
      setdateRangeFilled(true);
      setTdNotStaff(false);
    }

    if ((startDate !== null) && (endDate !== null)) {
      const date1: any = startDate;
      const date2: any = endDate;

      // To calculate the time difference of two dates
      const Difference_In_Time: number = date2.getTime() - date1.getTime();

      // To calculate the no. of days between two dates
      const Difference_In_Days: any = Difference_In_Time / (1000 * 3600 * 24) + 1;

      setdaysNumber(Math.round(Difference_In_Days));
    }
  }

  const [spanMonth, setSpanMonth] = useState(1);
  const identifySpanMonth = (startDate, endDate) => {
    const startMonth = moment(startDate).format('MM');
    const endMonth = moment(endDate).format('MM');
    const spanMonthNum = parseInt(endMonth) - parseInt(startMonth) + 1;
    setSpanMonth(spanMonthNum);
  }

  useEffect(() => {
    diffTwoDates(startDate, endDate);
    identifySpanMonth(startDate, endDate);
  }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  // get payload from all form
  const getPayload = () => {
    let tempRefinedRowsData: any[] = [];
    let tempFinalData = [];
    let dateOccupied = [];
    rowsData.forEach((item) => {
      dateOccupied.push(moment(item.SED[0]).format("YYYY-MM-DD"));
      dateOccupied.push(moment(item.SED[1]).format("YYYY-MM-DD"));
      tempRefinedRowsData.push({
        location: item.Location,
        dateRange: {
          start: moment(item.SED[0]).format("YYYY-MM-DD"),
          end: moment(item.SED[1]).format("YYYY-MM-DD")
        },
        purpose: item.purposeStateArr['purpose' as unknown as number],
        activityOrLeave: (item.activityStateArr[0]) ? (item.activityStateArr[0]['Activityname']) : (item.activityStateArr['Activityname' as unknown as number]),
        desc: item.Description

      })

    })

    setRefinedRowsData(tempRefinedRowsData);
    tempFinalData = [{
      "formData": {
        "status": "Pending",
        "submitter": {
          "givenName": userDetails[0].SubFN,
          "surname": userDetails[0].SubLN,
          "unikey": userDetails[0].SubUniKey,
        },
        "traveller": {
          "givenName": userDetails[0].TravFN,
          "surname": userDetails[0].TravLN,
          "unikey": userDetails[0].TravUniKey,
        },
        "fbt": isFringeBenefit,
        "onBehalfOf": userDetails[0].mySelfChecked,
        "travelAsStaff": userDetails[0].isStaffChecked ? false : true,
        "domestic": userDetails[0].isDomInterChecked ? false : true,
        "tripPeriod": {
          "start": dateRange[0] && moment(dateRange[0]).format('YYYY-MM-DD'),
          "end": dateRange[1] && moment(dateRange[1]).format('YYYY-MM-DD')
        },
        "tripDetails":
          tempRefinedRowsData
      }
    }];
    setFinalData(tempFinalData);
    return tempFinalData
  }

  const onHandleSaveSuccess = (response: any) => {
    setTravelId(response.id);
    setLoading(false);
    setIsStatusChanged(true);
  };

  const onUpdateError = (error) => {
    setLoading(false);
    if (error && error.status === 422) {
      showToast(ERROR_MESSAGE.SAVE_DETAILS);
    }
    else {
      showToast(ERROR_MESSAGE.UPDATE_DETAILS);
    }

  }

  const onhandleSubmit = (response: any) => {
    setLoading(false);
    onHandleSaveSuccess(response);
    setDeclarePopUp(true);
    // setSubmissionPopUp(true);
    setIsStatusChanged(true);
  }
  const onUpdateSave = (response) => {
    successToast(SUCCESS_MESSAGE.FORM_SAVED);
    onHandleSaveSuccess(response);
  }
  // logic for already existing travel details
  const onTravelDiaryExist = (fromSubmit?: Boolean, hasValidationError?: Boolean) => {
    const payload = getPayload();
    if (travelId) {
      let updatedData = {
        ...payload[0],
        "formData": {
          ...payload[0].formData,
          "status": "Pending",
        }
      };
      updateTravelDetails(travelId, updatedData, props.header, fromSubmit ? onhandleSubmit : !hasValidationError ? onUpdateSave : onHandleSaveSuccess, hasValidationError ? null : onUpdateError)
    }
    else {
      addTravelDetails(payload[0], props.header, onHandleSaveSuccess, onError)
    }
  }

  const onError = (error) => {
    setLoading(false);
    if (error && error.status === 422) {
      showToast(ERROR_MESSAGE.ADD_DETAILS);
    }
    else {
      if (error && error.data && error.data.message) {
        let errorMessage = error.data.message
        showToast(errorMessage);
      }
      else {
        showToast(ERROR_MESSAGE.API_ERROR);
      }
    }

  };
  const onSave = (response) => {
    successToast(SUCCESS_MESSAGE.FORM_SAVED);
    onHandleSaveSuccess(response);
  }
  // check if unikey is needed
  const checkIsUnikeyValid = (travelAsStaff, travellerUnikey) => {
    let isValidUnikey = false;
    if (travelAsStaff) {
      isValidUnikey = unikeyRegex(travellerUnikey);
    }
    else {
      if (travellerUnikey && travellerUnikey !== '') {
        isValidUnikey = unikeyRegex(travellerUnikey);
      }
      else {
        isValidUnikey = true;
      }
    }
    return isValidUnikey;
  };
  // logic for checking if rest day is valid or not
  const isRestValid = (isDomestic) => {
    if (!isDomestic) {
      let isRestDayPresent = rowsData && rowsData.filter((ele: any) => ele.purposeStateArr.purpose === 'Rest');
      if (isRestDayPresent && isRestDayPresent.length === 0) {
        return false
      }
      else if (isRestDayPresent.length === 1 && rowsData.length === 1) {
        // check only if one element is there or not
        return true;
      }
      else {
        let _date = isRestDayPresent && isRestDayPresent.length > 0 && isRestDayPresent[0].SED && isRestDayPresent[0].SED.length > 0 && isRestDayPresent[0].SED[0];
        if (_date) {
          let previousDay = moment(_date).subtract(1, 'd');
          let isFirstDay = moment(dateRange[0]).subtract(1, 'd');
          if (moment(previousDay).format('DD/MM/YYYY') === moment(isFirstDay).format('DD/MM/YYYY')) {
            return true
          }
          else {
            let currentDate = isRestDayPresent && isRestDayPresent.length > 0 && isRestDayPresent.map((_item) =>
              moment(_item.SED[0]).subtract(1, 'd').format('DD/MM/YYYY')
            )
              .filter(ele => ele);
            let isPresent = false;
            let mapData: any = rowsData && rowsData.map((_item: any) => {
              if (_item.SED && _item.SED.length > 0 && _item.SED[1]) {
                let date: any = moment(_item.SED[1]).format('DD/MM/YYYY')
                let isPrevDayPresent = currentDate.includes(date);
                if (isPrevDayPresent && (_item.purposeStateArr.purpose !== 'Travel')) {
                  isPresent = true;
                  return { ..._item }
                }
              }
              return null
            }).filter(ele => ele);

            if (isPresent) {
              return true
            }
            else {
              return false
            }


          }
        }

      }
    }
    else {
      return false;
    }
  }
  // Logic for save
  const handleSave = (hasValidationError: Boolean, fromSubmit?: Boolean) => {
    const payload = getPayload();
    const { travelAsStaff, domestic } = payload[0].formData;
    let uniKey = payload && payload[0].formData && payload[0].formData.submitter && payload[0].formData.submitter.unikey;
    let travellerUnikey = payload && payload[0].formData && payload[0].formData.traveller && payload[0].formData.traveller.unikey;
    let { givenName, surname } = payload[0].formData.traveller;
    const isUnikeyNeeded = (travelAsStaff && uniKey && travellerUnikey) || (!travelAsStaff && uniKey);
    const isRestDayCheck = isRestValid(domestic);
    if (isUnikeyNeeded && (givenName !== '' || surname !== '')) {

      let isValidUnikey = checkIsUnikeyValid(travelAsStaff, travellerUnikey);
      if (!isValidUnikey && !hasValidationError) {
        showToast(ERROR_MESSAGE.UNIKEY_ERROR);
      }
      else if (isRestDayCheck) {
        showToast(ERROR_MESSAGE.REST_DAY);
      }
      else {
        if (hasValidationError) {
          setLoading(false);
        }
        else if (!travelId || !hasValidationError) {
          setLoading(true);
        }
        travelId ? onTravelDiaryExist(fromSubmit, hasValidationError) : addTravelDetails(payload[0], props.header, fromSubmit ? onhandleSubmit : !hasValidationError ? onSave : onHandleSaveSuccess, !hasValidationError ? onError : null)
      }

    }
    else if (!hasValidationError) {
      showToast(ERROR_MESSAGE.TRAVELLER_UNIKEY)
    }
  }

  // logic for handleSubmit non staff

  const handleSubmitNonStaff = (hasValidationError: Boolean) => {
    const finalPayload = getPayload();
    let payload = [{
      ...finalPayload[0],
      "formData": {
        ...finalPayload[0].formData,
        "status": "Submitted",
        "tripDetails": []

      }
    }];
    const { travelAsStaff, domestic } = payload[0].formData;
    let uniKey = payload && payload[0].formData && payload[0].formData.submitter && payload[0].formData.submitter.unikey;
    let travellerUnikey = payload && payload[0].formData && payload[0].formData.traveller && payload[0].formData.traveller.unikey;
    let { givenName, surname } = payload[0].formData.traveller;
    const isUnikeyNeeded = (travelAsStaff && uniKey && travellerUnikey) || (!travelAsStaff && uniKey);
    if (isUnikeyNeeded && (givenName !== '' || surname !== '')) {

      let isValidUnikey = checkIsUnikeyValid(travelAsStaff, travellerUnikey);
      if (!isValidUnikey && !hasValidationError) {
        showToast(ERROR_MESSAGE.UNIKEY_ERROR);
      }
      else {
        if (hasValidationError) {
          setLoading(false);
        }
        else if (!travelId || !hasValidationError) {
          setLoading(true);
        }
        if (travelId) {
          updateTravelDetails(travelId, payload[0], props.header, onTravelDetailsAsNonStaffSuccess, onUpdateError)

        }
        else {
          addTravelDetails(payload[0], props.header, onTravelDetailsAsNonStaffSuccess, onError)

        }

      }

    }
    else if (!hasValidationError) {
      showToast(ERROR_MESSAGE.TRAVELLER_UNIKEY)
    }
  }

  const onTravelDetailsAsNonStaffSuccess = () => {
    let _initialData = {
      ...initialData,
      SED: []
    };
    setLoading(false);
    resetDataOnTabChange();
    setRowsData([_initialData]);
    // resetRow();
    successToast(SUCCESS_MESSAGE.TRAVEL_DIARY_COMPLETED);
    // onHandleSaveSuccess(response);
  }

  let TripBreakDownObj = {
    businessDays,
    businessDaysPerc,
    privateDays,
    privateDaysPerc,
    restDays,
    restDaysPerc,
    travelDays,
    travelDaysPerc
  }

  // Reset user details
  const resetUserDetails = () => {
    return {
      SubFN: apiUserDetails['givenName'], SubLN: apiUserDetails['surName'], SubUniKey: apiUserDetails['unikey'],
      requiredTravFN: false, requiredTravLN: false, requiredTravUniKey: false,
      TravFN: apiUserDetails['givenName'], isReadOnly: true, TravLN: apiUserDetails['surName'], TravUniKey: apiUserDetails['unikey'],
      mySelfChecked: false, isStaffChecked: false, isDomInterChecked: false, startDate: null, endDate: null, declarationCheck: false
    }
  }
  // Reset values on Tab Switch
  const resetDataOnTabChange = () => {
    resetTravelDiary([null, null]);
    setDateRange([null, null]);
    let resetData = resetUserDetails();
    setUserDetails([resetData]);
    dispatch(setResumeTravelDetails({ isResume: false, details: [] }));
    // dispatch(setCopyTravelDetails({ isCopy: false, details: [] }));
    setinputFocus(false);
    setTravelId(null);
    setIsFringeBenefit(false)
  }
  // Tab change logic
  const onTabChange = (key: any) => {
    if (key !== 'viewForm') {
      setKey(key);
    }
    if (key === 'mydashboard') {
      resetDataOnTabChange();
    }
  };

  // Reset Form
  const resetTravelDiary = (dateArray: any[]) => {
    if (!dateArray[0] && !dateArray[1]) {
      let _initialData = {
        ...initialData,
        SED: []
      };
      setcalendarDates([]);
      setRowsData([_initialData]);
      setexcludedDates([]);
      leaveNotification();
    }

  }
  // // Logic for Resume travel details
  const onResumeTravelDetails = () => {
    if (resumePayload && resumePayload.hasOwnProperty('formData')) {
      const { domestic, tripPeriod, traveller, tripDetails, submitter, travelAsStaff, onBehalfOf } = resumePayload.formData;
      let _startDate = moment(tripPeriod.start, 'YYYY-MM-DD').toDate(), _endDate = moment(tripPeriod.end, 'YYYY-MM-DD').toDate();
      let selectedCalDates = getDatesInRange(_startDate, _endDate);
      setTravelId(resumePayload.id);

      setDateRange([_startDate, _endDate])
      setIsDomInterChecked(!(domestic));
      diffTwoDates(_startDate, _endDate);
      setIsStaffChecked(!travelAsStaff);
      setUserDetails([
        { SubFN: submitter.givenName, SubLN: submitter.surname, SubUniKey: submitter.unikey, requiredTravFN: requiredTravFN, requiredTravLN: requiredTravLN, requiredTravUniKey: requiredTravUniKey, TravFN: traveller.givenName, isReadOnly: !onBehalfOf, TravLN: traveller.surname, TravUniKey: traveller.unikey, mySelfChecked: onBehalfOf, isStaffChecked: !travelAsStaff, isDomInterChecked: !domestic, startDate: _startDate, endDate: _endDate, declarationCheck: declarationCheck }]);
      setData(tripDetails, selectedCalDates, !domestic)
    }
  };

  // prepopulate data from API for Copy/Resume
  const setData = (tripDetails, selectedCalDates, isDomestic) => {
    let _purposeList = DD && DD.filter(item => item.purpose !== 'Rest');
    let calArr = [], excludedDatesArray = [];
    let tempArr = tripDetails.map((item, index) => {
      let _startDate = moment(item.dateRange.start, 'YYYY-MM-DD').toDate(), _endDate = moment(item.dateRange.end, 'YYYY-MM-DD').toDate();
      let _purpose: any = DD && DD.filter(arr => arr.purpose === item.purpose),
        activityArr = _purpose[0] && _purpose[0].activity.filter(_activity => _activity.Activityname === item.activityOrLeave);
      let dates = getDatesInRange(_startDate, _endDate);

      dates && dates.map(ele => {
        excludedDatesArray.push(ele);
        let month = moment(ele).format('MM'),
          day = moment(ele).format('DD'),
          year = moment(ele).format('YYYY');
        calArr.push({ month: Number(month), day: Number(day), year: Number(year), className: item.purpose })
        return { month: Number(month), day: Number(day), year: Number(year), className: item.purpose }
      })
      return {
        id: index,
        Location: item.location,
        locationValid: locationValid,
        SED: [_startDate, _endDate],
        Purpose: item.purpose,
        purposeStateArr: _purpose[0],
        purposeStateColor: item.purpose,
        activityStateArr: activityArr[0],
        purposeList: isDomestic ? DD : _purposeList,
        activityList: _purpose[0].activity,
        Activity: item.activityOrLeave,
        Description: item.desc,
        SEDValid: false,
        PurposeValid: false,
        ActivityValid: false,
        DescriptionValid: false,
        checkBox: checkBox,
        enableSED: false,
        enablePurpose: false,
        enableActivityDesc: false,
        enableDesc: false
      }
    })
    tempArr.length === 0 ? setRowsData([{
      ...initialData,
      SED: []
    }]) : setRowsData(tempArr)
    // setRowsData(tempArr);
    setEnableSubmitButton(true);
    setIsVisited(true);
    let _dates = selectedCalDates && selectedCalDates.map((item) => {
      let month = moment(item).format('MM'),
        day = moment(item).format('DD'),
        year = moment(item).format('YYYY'),
        isPresent = calArr && calArr.filter(_ele => (_ele.month === Number(month)) && (_ele.year === Number(year)) && (_ele.day === Number(day)))
      return {
        month: Number(month),
        year: Number(year),
        day: Number(day),
        className: isPresent && isPresent.length > 0 ? isPresent[0].className : "default-color"
      }
    });
    setexcludedDates(excludedDatesArray);
    setcalendarDates(_dates);
    setinputFocus(true);
    setIdCounter(tempArr.length);
  }
  // Logic for Copy
  const onCopyTravelDetails = () => {
    if (copyPayload && copyPayload.hasOwnProperty('formData')) {
      const { domestic, tripPeriod, tripDetails, travelAsStaff } = copyPayload.formData;
      let _startDate = moment(tripPeriod.start, 'YYYY-MM-DD').toDate(), _endDate = moment(tripPeriod.end, 'YYYY-MM-DD').toDate();
      let selectedCalDates = getDatesInRange(_startDate, _endDate);
      setDateRange([_startDate, _endDate])
      setIsDomInterChecked((!domestic));
      diffTwoDates(_startDate, _endDate);
      let resetData = resetUserDetails(); /*only for copy */
      setIsStaffChecked(!travelAsStaff);
      setUserDetails([{ ...resetData, isDomInterChecked: !domestic, isStaffChecked: !travelAsStaff }]);/*only for copy */
      setTravelId(null);/*only for copy */
      let _isDomestic = !(domestic)
      setData(tripDetails, selectedCalDates, _isDomestic);
    }
  }

  useEffect(() => {
    if ((isVisited && isResume) || (isVisited && isCopy)) {
      setIsVisited(false)
    }
  }, [isVisited]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isResume) {
      onResumeTravelDetails();
    }

  }, [isResume]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isCopy) {
      onCopyTravelDetails();
    }
  }, [isCopy]);// eslint-disable-line react-hooks/exhaustive-deps

  // reset declaration form
  const resetDeclaration = () => {
    setDeclarePopUp(false)
    setDeclarationCheck(false);
    setUserDetails(userDetails.map(user => user ? ({
      ...user,
      declarationCheck: false

    }) : user));
  };

  // calendar close event for incomplete selection
  const handleCalendarClose = () => {
    if (dateRange && !dateRange[1]) {
      setDateRange([null, null]);
      setRowsData([initialData]); // Clear Domestic rows
      setTempInterData([initialData]); //Clear International rows
    }
  }
  // on calendar close for table rows for incomplete selection
  const onTravellCalendarClose = (id, index) => {
    if (SED && !SED[1]) {
      handleSEDChange(id, [null, null], index);
      let _dates = excludedDates && excludedDates.map(ele => {
        let mapper = dateMapper(ele)
        return {
          ...mapper

        }
      });
      let newDates = calendarDates && calendarDates.map(_item => {
        let isPresent = _dates && _dates.length > 0 && _dates.filter(_ele => (_ele.month === _item.month) && (_ele.year === _item.year) && (_ele.day === _item.day))
        return {
          ..._item,
          className: isPresent && isPresent.length > 0 ? _item.className : 'default-color'
        }
      })
      setcalendarDates(newDates);
      setRowsData(rowsData.map((item) => {
        return {
          ...item,
          SEDValid: item.id === id ? true : item.SEDValid,
          SED: item.id === id ? [null, null] : item.SED
        }
      }))
    }
    else {
      setRowsData(rowsData.map((item) => {
        return {
          ...item,
          SEDValid: item.id === id ? false : item.SEDValid,
        }
      }))
    }
  }

  return (
    <>
      {loading && <Loader />}
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k: any) => onTabChange(k)}
        className={`right-tabs animated fadeIn go me-5 pe-5`}
        style={{ borderWidth: '0px' }}
      >
        <Tab eventKey="traveldetails" title="Travel Details" className="right-tabs-nav-tabs">
          <section className={`${styles['form-container']} bg-fff m-4 flex-grow-1 justify-content-between d-flex flex-column`}>
            <UserDetails handleStaffMember={handleStaffMember} handleMyselfChange={handleMyselfChange} handleInputChange={handleInputChange} userDetails={userDetails} />
            <div className='row w-100'>
              <div className="d-flex flex-column col-6 mx-4 align-items-start position-relative z-index-9 col-sm-8 col-md-8 col-lg-6">
                <h1 className="OpenSansSemiBold size-20">Travel Details</h1>
                <p className="size-16">Is the travel Domestic or International? {" "}<DomesticInternationalTooltip /></p>
                <div className="d-flex flex-row align-items-center">
                  <label className="checker  flight col-5">
                    <input className="checkbox" name="isDomInter" checked={userDetails[0].isDomInterChecked}
                      onChange={(e) => handleDomInter()} type="checkbox" />
                    <div className="check-bg"></div>
                    <div className="d-flex flex-row justify-content-between text-toggle">
                      <div className="d-flex flex-row align-items-center ps-3 col-6">
                        <span className="d-flex"><img src={userDetails && userDetails[0].isDomInterChecked === false ? DomesticWhite : DomesticIcon} alt="Domestic Icon" /></span>
                        <span className="size-16 ps-0 col-7 ps-2 text-toggle-span" style={{ color: userDetails && userDetails[0].isDomInterChecked === false ? "white" : '#4D4F5C' }}>Domestic</span>
                      </div>
                      <div className="d-flex flex-row align-items-center ps-3 ms-1">
                        <span className="d-flex ps-3"><img src={userDetails && userDetails[0].isDomInterChecked === true ? InternationalWhite : InternationalIcon} alt="International Icon" /></span>
                        <span className="size-16 ps-2 col-7 text-toggle-span" style={{ color: userDetails && userDetails[0].isDomInterChecked === true ? "white" : '#4D4F5C' }}>International</span>
                      </div>
                    </div>
                    <div className="checkmark">
                      <svg viewBox="0 0 100 100">
                      </svg>


                    </div>
                  </label>
                  <div className="label-float  col-5  ms-4" style={{ marginRight: "2.5rem !important" }}>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <DatePicker
                        showPopperArrow={false}
                        dateFormat="dd/MM/yyyy"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        popperPlacement="bottom-start"
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        monthsShown={2}
                        onFocus={labelTop}
                        onBlur={labelTop}
                        onCalendarClose={handleCalendarClose}
                        onChange={(update: any) => {
                            setDateRange(update);
                            resetTravelDiary(update)
                        }}
                        calendarStartDay={1}
                        isClearable={true}
                      />
                      <span className="icon"><img src={calendarIcon} alt="Calendar icon" /></span>
                      <label className="focus-date-range">Start and End Date</label>
                      <DateRangeTooltip />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-4">
                <div style={{ marginTop: '4rem !important' }} className={`${(dateRangeFilled) ? 'd-none' : 'd-flex'} flex-wrap align-items-center trip-breakdown my-5 margintop-wrapper flex-wrap ps-lg-0`} >
                  <TripBreakdownComponent
                    fromHome={true}
                    domIntCheck={isDomInterChecked}
                    {...TripBreakDownObj}
                  />
                </div>
              </div>

            </div>

            {tdNotStaff && <><div className="modal-body event-content-body px-4 d-flex flex-column  travelDiaryClass">
              <p className="OpenSansSemiBold size-20 color-000 text-align-center pb-4">
                <span className="pe-3 mb-2 d-flex justify-content-center"><img src={SuccessIcon} alt="success icon" /></span><br />
                <span>Travel Diary has been completed</span>
                <br /><span>You are not travelling as University staff, so no further information is required</span>

              </p>

            </div>
              <div className="text-align-center"><button className="btn btn-secondary px-5 py-2 me-3 save" onClick={() => handleSubmitNonStaff(false)} type="button">Submit</button></div>
            </>}


            <div className={`${(dateRangeFilled) ? 'd-none' : 'd-flex'} ${styles['travel-agenda-wrap']}`}>
              <div className='row w-100 ps-4 flex-wrap'>
                {/**Travel Agenda*/}
                <div className='d-flex flex-column col flex-grow'>
                  <div className={`d-flex flex-column ${styles['travel-agenda']} mt-4 `}>
                    <div>
                      {
                        showSSPAlert && <SSPNotification/>
                      }
                      {showAutoBookLeave &&
                        <LeaveNotification annual_LSLNotification={annual_LSLNotification} totalLeavesNot={totalLeavesNot} />}
                      {showManualLeave && <OtherLeaveNotification otherLeaveNotification={otherLeaveNotification} />}

                      {
                        isFringeBenefit ? (<div>
                          <Alert variant="warning" >
                            <div className="d-flex flex-row align-items-start">
                              <p className="OpenSansSemiBold  size-18 color-000 mb-0 fringeClass">
                                {/* <span className="pe-3"><img src={WarningIcon} alt="success icon" /></span> */}
                                <span>I acknowledge that fringe benefits tax (FBT) applies to this trip because the private component is 30% or more.<br />As per the Travel Procedures 2018, I accept that I will be invoiced 50% of the airfare cost by the University.<br />Please refer to {" "} <a target="_blank" rel="noreferrer" href={LINKS.FRINGE_BENEFIT_LINK}>Section 17.4 of Travel Procedures 2018</a> {" "} for payment options.</span>
                              </p>
                            </div>
                          </Alert>

                        </div>) : <></>
                      }
                    </div>

                    <div>
                      {
                        showSuccessAlert ?
                          (<>
                            <Alert variant="success" onClose={() => setShowSuccessAlert(false)} >
                              <div className="d-flex flex-row">
                                <img src={SuccessIcon} alt="Alert icon" className="ms-2" width="20" />
                                <div className="ms-3">

                                  <p className="color-2D2D38 size-14 mb-0">Successfull submission  </p>
                                  <p className="color-2D2D38 size-12 mb-0"></p>

                                </div>
                              </div>
                            </Alert>

                          </>) : (<></>)
                      }
                    </div>


                    {isDomInterChecked ? (
                      <div className="d-flex flex-row justify-content-between">
                        <h1 className="OpenSansSemiBold size-20">Travel Agenda</h1>
                        <div className="legend d-flex flex-row align-items-center">
                          <div className="d-flex flex-row align-items-center me-3">
                            <span className="business-color legend-indicator"></span>
                            <span className="ps-2">Business</span>
                          </div>
                          <div className="d-flex flex-row align-items-center me-3">
                            <span className="private-color legend-indicator"></span>
                            <span className="ps-2">Private</span>
                          </div>
                          <div className="d-flex flex-row align-items-center me-3">
                            <span className="rest-color legend-indicator"></span>
                            <span className="ps-2">Rest</span>
                          </div>
                          <div className="d-flex flex-row align-items-center me-3">
                            <span className="travel-color legend-indicator"></span>
                            <span className="ps-2">Travel</span>
                          </div>
                          <div className="d-flex flex-row align-items-center me-3">
                            <span className="unaccounted-color legend-indicator"></span>
                            <span className="ps-2">Unaccounted</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex flex-row justify-content-between">
                        <h1 className="OpenSansSemiBold size-20">Travel Agenda</h1>
                        <div className="legend d-flex flex-row align-items-center">
                          <div className="d-flex flex-row align-items-center me-3">
                            <span className="business-color legend-indicator"></span>
                            <span className="ps-2">Business</span>
                          </div>
                          <div className="d-flex flex-row align-items-center me-3">
                            <span className="private-color legend-indicator"></span>
                            <span className="ps-2">Private</span>
                          </div>
                          <div className="d-flex flex-row align-items-center me-3">
                            <span className="travel-color legend-indicator"></span>
                            <span className="ps-2">Travel</span>
                          </div>
                          <div className="d-flex flex-row align-items-center me-3">
                            <span className="unaccounted-color legend-indicator"></span>
                            <span className="ps-2">Unaccounted</span>
                          </div>
                        </div>
                      </div>
                    )}



                    <DragDropContext onDragEnd={handleDragEnd}>
                      <table id="tableAgenda" className={`${styles.tableagenda} table-light mt-3  tbl_code_with_mark table`}>
                        <thead>
                          <tr>
                            <th>
                            </th>
                            <th className="col"><input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            /></th>
                            <th className="col-2">Location {" "}<LocationTooltip /></th>
                            <th className={`col-2 ${styles['SED-row']}`}><span className="d-flex flex-row justify-content-between me-3 align-items-center">Start and End Date <span>
                              <button onClick={() => {
                                isAscending ? sortDsc() : sortAsc()
                                setIsAscending(!isAscending)
                              }}>
                                <img width="18" src={isAscending ? SortAscImage : SortDescImage} alt="Sort Ascending Icon" />
                              </button>
                            </span></span></th>
                            <th className="col-2 col-xs-1">Purpose <PurposeTooltip /></th>
                            <th className="col-2 col-xs-1">Main Activity | Leave Type</th>
                            <th className="col-3 col-xs-2">Description</th>
                            <th>{(enableDelete || selectAll) && <button className={`${styles['delete-all']}`} onClick={handleDeleteAll}><img src={DeleteIcon} alt="Delete All Icon" /></button>}
                            </th>
                          </tr>
                        </thead>

                        <TableRows excludedDates={excludedDates} handleExcludedDate={handleExcludedDate} handleExcludedDateOnBlur={handleExcludedDateOnBlur} handleSEDChange={handleSEDChange} dateRange={DateRangeProp} handleLocationChange={handleLocationChange} rowsData={rowsData} deleteTableRows={deleteTableRows} purposeStateColor={purposeStateColor} addTableRows={addTableRows} handleChange={handleChange} handlePurposeChange={handlePurposeChange} handleactivityChange={handleactivityChange} handleDescriptionChange={handleDescriptionChange} handleCheckbox={handleCheckbox} onCalendarClose={onTravellCalendarClose} />
                      </table>
                    </DragDropContext>
                  </div>
                </div>

                {/* Calendar> */}
                <div className="d-flex flex-column calendar-column">
                  <p className='tripClass OpenSansSemiBold col-13'>Trip Duration: {daysNumber ?? 0} days</p>
                  <div className="calendar-view">
                    {spanMonth > 1 ? <p className="color-000 mx-3">* Scroll to view the selected calendar months</p> : ""}
                    <div className="mx-3">
                      {isPrivateWeekend ? <BusinessWeekendTooltip /> : (<div></div>)}
                      {hasPublicHoliday ? <PublicHolidaysTooltip /> : (<div></div>)}
                    </div>
                    <TripCalendar calendarDates={calendarDates} />
                  </div>
                  <div className="d-flex flex-row my-3 mx-3" style={{ display: "flex", justifyContent: "flex-end" }}>
                    {enableSubmitButton ? (<><button className="btn btn-secondary mx-2 col save" style={{ backgroundColor: "#EDA44C", color: "#FFFFFF" }} type="button" onClick={() => handleSave(false)} >Save </button><button className="btn btn-secondary col mx-2 save" style={{ backgroundColor: "#4AADE8", color: "#FFFFFF" }} type="button" onClick={handleValidation} >Submit</button></>) : (<></>)}
                  </div>
                </div>
              </div>
            </div>


            <div className={`${(dateRangeFilled) ? 'd-none' : 'd-flex'} flex-row  mx-4 align-items-center trip-breakdown my-5`} style={{ marginTop: '4rem !important' }}>
              <div className="d-flex flex-row justify-content-end w-100">

                <div className={`modal ${declarePopUp ? 'show' : 'fade'}`} style={{ display: declarePopUp ? 'block' : '', background: 'rgba(0, 0, 0, .9)' }}
                  id="declarePopUp" tabIndex={-1} aria-labelledby="declarePopUpLabel" aria-hidden="false">
                  <div className="modal-dialog event-dialog">
                    <div className="modal-content event-content d-flex justify-content-center">
                      <div className="modal-header event-content-header d-flex">
                        <p className="modal-title size-35 OpenSansBold pt-4 color-000" id="declarePopUpLabel">Extract Travel Diary</p>
                        <button type="button"
                          onClick={() => setDeclarePopUp(false)}
                          className="close" data-bs-dismiss="modal" aria-label="Close"><img src={closeIcon} alt="Close icon" /></button>
                      </div>
                      <div className="modal-body event-content-body px-4 d-flex flex-column ">
                        <p className="OpenSansSemiBold size-20 color-000">
                          <span className="pe-3"><img src={SuccessIcon} alt="success icon" /></span>
                          <span>Yay! Travel Diary has been completed</span>

                        </p>
                        {showAutoBookLeave &&
                          <LeaveNotification annual_LSLNotification={annual_LSLNotification} totalLeavesNot={totalLeavesNot} />}
                        {showManualLeave && <OtherLeaveNotification otherLeaveNotification={otherLeaveNotification} />}
                        {showSSPAlert && <SSPNotification />}

                        {privateDaysPerc >= 30 && <p className="Ope size-20 color-000 fringe-bg d-flex flex-row py-4 px-3">
                          <span className="pe-3"><img src={ErrorIcon} alt="success icon" /></span>
                          <span className="size-14">I acknowledge that fringe benefits tax (FBT) applies to this trip because the private component is 30% or more. As per the Travel Procedures 2018, I accept that I will be invoiced 50% of the airfare cost by the University. Please refer to <a target="_blank" rel="noreferrer" href={LINKS.FRINGE_BENEFIT_LINK}>Section 17.4 of Travel Procedures 2018</a> for payment options.</span>

                        </p>}

                        {(privateDaysPerc === 0 || privateDaysPerc < 30) && <div className="d-flex flex-row align-items-center px-2 py-3 declare-bg">

                          <input
                            type="checkbox"
                            checked={userDetails[0].declarationCheck}
                            onChange={() => (handleDeclaration())}
                          /><p className="OpenSansSemiBold size-16 color-000 text-center mb-0 ms-3">I declare the info given is true to my knowledge</p>
                        </div>}
                      </div>
                      <div className="modal-footer event-content-footer d-flex  mb-3">

                        <button type="button"
                          onClick={() => resetDeclaration()}
                          className="btn btn-cancelModal btn-secondary OpenSansSemiBold size-24 color-666666 px-4 py-1" data-bs-dismiss="modal">Cancel</button>

                        {(declarationCheck || (privateDaysPerc >= 30)) ? (<button type="button"

                          className="btn btn-okModal btn-primary OpenSansSemiBold size-24 color-333333 bg-ffe600 px-4 py-1" data-bs-dismiss="modal" onClick={handleCreateTD}>{(privateDaysPerc >= 30) ? 'Ok' : 'Confirm'}</button>) : (<></>)}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${dateRangeFilled ? 'd-flex' : 'd-none'} graphical-illustration position-relative  justify-content-end align-items-end flex-column align-self-end`}>

              <figure className="path m-0">
                <PathIllustration />

              </figure>

              <figure className="traveller animated fadeInRightShort go delay-250">
                <img src={WomanIllustration} alt="Illustration of a woman" />
              </figure>

              <figure className="board animated fadeInRightShort go delay-500">
                <img src={TravelIllustration} alt="Illustration of a travel board" />
              </figure>
            </div>

          </section>

        </Tab>
        {key === "viewForm" && <Tab eventKey="viewForm" title="View Form" style={{ cursor: key === 'viewForm' ? 'pointer' : 'not-allowed' }} className="right-tabs-nav-tabs abc " tabClassName={key === 'viewForm' ? 'cursor-pointer' : 'cursor-not-allowed'}>
          {key === 'viewForm' && <TravelDetailsView rowsData={rowsData} userDetails={userDetails} customDaysClassName={calendarDates} tripBreakdown={TripBreakDownObj} travelId={travelId} tripPeriod={dateRange}
            loading={(flag: any) => setLoading(flag)} resetDataOnTabChange={() => resetDataOnTabChange()} header={props.header} />}
        </Tab>}
        <Tab eventKey="mydashboard" title="My Dashboard" className="right-tabs-nav-tabs">
          {key === "mydashboard" && <MyDashboardView unikey={userDetails && userDetails[0].TravUniKey} onCopy={() => setKey('traveldetails')} header={props.header} />}
        </Tab>
      </Tabs>
      <ToastContainer />
    </>
  );
};

export default TravelDetails;
