import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import 'react-dropdown/style.css';
import './public/scss/__button.scss'
import './public/scss/__colors.scss'
import './public/scss/__fonts.scss'
import './public/scss/__typography.scss'
import './public/scss/__animation.scss'
import './public/scss/__modal.scss'
import './public/scss/__style.scss'
import './public/scss/__customstyle.scss'
import App from './TravelDiary';
import reportWebVitals from './reportWebVitals';
import store from './store';

// import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";
// import { msalConfig } from "./config/authConfig";

// const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <Provider store={store}>
      <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
