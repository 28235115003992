/*Common reusable component for Annual/Long leave notification */

import React from 'react';
import Alert from 'react-bootstrap/Alert';
import SuccessIcon from '../../public/assets/success-icon.svg';
import { LINKS } from '../../constants';

export default function AnnualLeave(props) {
    const { annual_LSLNotification, totalLeavesNot } = props; 
    return (
        <div className="text-left">
            <Alert variant="success" >
                <div className="d-flex flex-row align-items-start">
                    <p className="OpenSansSemiBold size-18 color-000 mb-0">
                        <span className="pe-3"><img src={SuccessIcon} alt="success icon" /></span>
                        <span>The traveller's {annual_LSLNotification} will be booked automatically</span>
                    </p>
                </div>
            </Alert>
            <p className="text-left">
                Once this travel request is approved on Concur, the <b>{annual_LSLNotification}</b> noted in this travel diary will be submitted to HR and booked for the traveller in <a target="_blank" rel="noreferrer" href={LINKS.WORKDAY_LINK} >Workday</a>. {totalLeavesNot} {totalLeavesNot > 1 ? "days" : "day"} will be booked (weekends excluded). The traveller will receive booking notifications via Workday.
                <br /><br />
                Bookings are full day only. For part day bookings, please change the leave type to <b>Other</b> and book manually.
            </p>
        </div>
    )
}