import React from 'react';

function Loader() {
    return (
        <div className={`loader inline-loader`} id="loader-section">
        <div>
          <div className="dot-loader" />
          <div className="dot-loader dot-loader--2" />
          <div className="dot-loader dot-loader--3" />
        </div>
      </div>
    )
};

export default Loader