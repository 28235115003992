import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react'
import styles from './navbar.module.scss'
import * as fromAppModels from '../../models/AppModels';
import Logo from '../../public/assets/the-university-of-sydney-logo.svg';
import Divider from '../../public/assets/divider.svg';
import Avatar from '../../public/assets/Avatar.svg';
import { LINKS } from '../../constants';
    


const Header: React.FunctionComponent<fromAppModels.IHeaderProps> = (props:any) => {
  const userData = props.userData ? props.userData : {'givenName':'', 'surName':'', 'unikey':''};
  const givenName = userData.givenName
  const surName = userData.surName;
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();

  const onNavigate = () => {
    history.replace('/');
  }

  // Note: Can't distinguish CORS error from other network errors
  const isCorsError = (err) => (err.name === 'AuthApiError' && !err.errorCode && err.xhr.message === 'Failed to fetch');

  const logout = async () => {
    const basename = window.location.origin + history.createHref({ pathname: '/' });

    try {
      await oktaAuth.signOut({ postLogoutRedirectUri: basename });
    } catch (err) {
      throw err;
    }
  };

  return (
    <Navbar bg="light" expand="lg" className={` ${styles['app-navbar']}  py-2 px-lg-5 px-4 justify-content-between d-flex flex-row align-items-center`}>
      <Container fluid>
        <Navbar.Brand className="d-lg-flex d-flex flex-column flex-lg-row align-items-lg-center align-items-center flex-lg-grow-1 ms-lg-0 mt-lg-0 mt-2 text-dec-none" onClick={onNavigate}>
          <img src={Logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto d-flex flex-row align-items-center">

            <Nav.Link className="color-2865AC size-18 text-dec-none  me-4" target="_blank" rel="noreferrer" href={LINKS.HOW_TO_GUIDE}>
              “How To” Guide
            </Nav.Link>
            <Nav.Link className="color-2865AC size-18 text-dec-none" target="_blank" rel="noreferrer" href={LINKS.TRAVEL_POLICIES_AND_PROCEDURES}>
              Travel Policies &amp; Procedures
            </Nav.Link>

            <div className="mx-3">
              <img src={Divider} alt="Divider" />
            </div>

            {/* <Dropdown className="mx-4" options={options} onChange={handleOptionSelection} value={values.fileType} placeholder="Select an option" />; */}
            <Nav.Link>
              <div className="nav-item dropdown mx-4" id="myDropdown">
                <a className={`nav-link dropdown-toggle ${styles['nav-dropdown-toggle']}  InterstateLight color-4D4F5C size-16 d-flex flex-row align-items-center`} href="/#" data-bs-toggle="dropdown" aria-expanded="false">{givenName} {surName}</a>
                <ul className="dropdown-menu py-0">
                  <li className="d-flex mx-1"> <a className="dropdown-item" href="/#" onClick={logout}>Sign Out</a> </li>
                  {/* <li className="d-flex mx-3"><SignOutButton/></li> */}
                </ul>
              </div>
            </Nav.Link>
            <img width="50px" src={Avatar} alt="" className="rounded-circle" />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;


