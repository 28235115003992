export const PurposeActivityList = [

    {
      "purpose": "Business",
      "activity": [
        {
          "Activityname": "Conference",
          "id": "1"
        },
        {
          "Activityname": "Meetings",
          "id": "2"
        },
        {
          "Activityname": "Interviews",
          "id": "3"
        },
        {
          "Activityname": "Research",
          "id": "4"
        },
        {
          "Activityname": "Fieldwork",
          "id": "5"
        },
        {
          "Activityname": "Projects",
          "id": "6"
        },
        {
          "Activityname": "Surveys",
          "id": "7"
        },
        {
          "Activityname": "SSP - Special Program",
          "id": "8"
        },
        {
          "Activityname": "Remote Working",
          "id": "9"
        }
      ]
    },
    {
      "purpose": "Private",
      "activity": [
        {
          "Activityname": "Annual Leave",
          "id": "1"
        },
        {
          "Activityname": "Long Service Leave",
          "id": "2"
        },
        {
          "Activityname": "Flexible Time Off",
          "id": "3"
        },
        {
          "Activityname": "Leave Without Pay",
          "id": "4"
        },
        {
          "Activityname": "Public Holiday",
          "id": "5"
        },
        {
          "Activityname": "Weekend",
          "id": "6"
        },
        {
          "Activityname": "Other Leave",
          "id": "7"
        }
      ]
    },
    {
      "purpose": "Rest",
      "activity": [
        {
          "Activityname": "N/A",
          "id": "1"
        }
      ]
    },
    {
      "purpose": "Travel",
      "activity": [
        {
          "Activityname": "N/A",
          "id": "1"
        }
      ]
    },

  ];
export const sampleUser = [
    {
      'givenName': 'Sai Leena',
      'surName': 'Y',
      'uniKey': '123-456-789-100',

    }
  ];