
import moment from "moment";
import 'moment-timezone';

export function GetLocalDateTime(dateString: any) {
  const localDateTime = moment.utc(dateString, 'YYYY-MM-DD').local().format("YYYY-MM-DD");

  return localDateTime;

}
// get dates in range
export function getDatesInRange(startDate: any, endDate: any) {
  const date = new Date(startDate.getTime());
  const dates: any[] = [];
  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dates;
}


// Format time as per sydney timezone

export const formatDateTime = (_date, format) => {
  return moment(_date).tz("Australia/Sydney").format(format);
}


// Check whether date range excludes weekend

export const getDaysOfWeekBetweenDates = (sDate, eDate) => {
  const startDate = moment(sDate)
  const endDate = moment(eDate);

  endDate.add(1, "day");

  const daysOfWeek = [];

  let i = 0;

  while (i < 7 && startDate < endDate) {
    daysOfWeek.push(startDate.day());
    startDate.add(1, "day");
    i++;
  }
  if (daysOfWeek.includes(1) || daysOfWeek.includes(2) || daysOfWeek.includes(3) || daysOfWeek.includes(4) || daysOfWeek.includes(5)) {
    return true
  }
  else {
    return false
  }
};


// format date mapper

export const dateMapper = (item) => {
  let month = moment(item).format('MM'),
    day = moment(item).format('DD'),
    year = moment(item).format('YYYY')

  return {
    month: Number(month),
    day: Number(day),
    year: Number(year)
  }

}




export const calDatesUtility = (dateRange, rowsData, datesToHighlight) => {
  
  let calArr = [], excludedDatesArray = []; let _dates = [];
  let calStyle = []
  let selectedCalDates = getDatesInRange(dateRange[0], dateRange[1]);

  if (dateRange.length !== 0 && !dateRange.includes(null)) {
    rowsData && rowsData.forEach((item) => {
      const validSED = item.SED.length !== 0 && !item.SED.includes(null)
      if(validSED){
        let dates = item.SED[0] && item.SED[1] && getDatesInRange(item.SED[0], item.SED[1]);
        // Excluded dates array
        dates && dates.map(ele => {
          excludedDatesArray.push(ele);
          calArr.push({ ...dateMapper(ele), className: item.purposeStateColor })
          return { ...dateMapper(ele), className: item.purposeStateColor }
        })
        
        // Date style
        dates && dates.map(selectedDate => {
          let month = moment(selectedDate).format('MM'),
            day = moment(selectedDate).format('DD'),
            year = moment(selectedDate).format('YYYY'),
            isPresent = calArr && calArr.filter(_ele => (_ele.month === Number(month)) && (_ele.year === Number(year)) && (_ele.day === Number(day)))
          let dateClass = ''
          if (isPresent && isPresent.length > 0){
            const isPresentClassname = isPresent[0].className ? isPresent[0].className : "default-color"
            const highlight = datesToHighlight.includes(selectedDate.getTime());
            dateClass = highlight ? "PrivateBusiness" : (item.activityStateArr['Activityname'] === 'Public Holiday') ? "PrivateBusiness" : isPresentClassname
          }
          else{
            dateClass = "default-color"
          }
  
          calStyle.push({
            month: Number(month),
            year: Number(year),
            day: Number(day),
            className: dateClass
          })
          return null;
        })
      }
    })

    // The rest date array
    const excludedLocalTime = excludedDatesArray.map(dateItem => GetLocalDateTime(dateItem))
    const restDates = selectedCalDates.filter(dateItem => !excludedLocalTime.includes(GetLocalDateTime(dateItem)))
    
    restDates && restDates.map(date => {
      let month = moment(date).format('MM'),
        day = moment(date).format('DD'),
        year = moment(date).format('YYYY'),
        dateClass = "default-color"
      
      calStyle.push({
        month: Number(month),
        year: Number(year),
        day: Number(day),
        className: dateClass
      })
      return null;
    })
  }

  
  return {
    excludedDatesArray,
    calStyle
  }

}