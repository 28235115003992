import { ToastContainer, toast } from 'react-toastify';


// general function to create pdf from array buffer

export const onPdfgenerate = (response: any) => {
  const blob: any = new Blob([response], { type: 'application/pdf; charset=utf-8' });
  const url = window.URL.createObjectURL(blob);
  // window.open(url);
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', "TravelDetails.pdf");
  //   loading(false);
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
  return url
}

// show toast message

export const errorToast = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    theme: "colored",
  },)
}

export const infoToast = (message) => {
  toast.info(message, {
    position: toast.POSITION.TOP_CENTER,
    theme: "colored",
  },)
}

// Regex for unikey

export const unikeyRegex = (unikey) => {
  let regex = /^[a-zA-Z0-9_-]/;
  let string = unikey.split("");
  let hyphenUnderscoreValidation = string && string.length>0 && (string[0]!=='-' && string[string.length-1]!=='-'&& string[0]!=='_' && string[string.length-1]!=='_')
 if(regex.test(unikey)&&hyphenUnderscoreValidation){
  return true
 }
 else{
  return false
 }
}

// success Toast
export const successToast = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    theme: "colored",
  },)
}


export const CustomLocale = {
  // months list by order
  months: [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
  ],

  // week days by order
  weekDays: [
  
    {
      name: 'Monday',
      short: 'M',
    },
    {
      name: 'Tuesday',
      short: 'T',
    },
    {
      name: 'Wednesday',
      short: 'W',
    },
    {
      name: 'Thursday',
      short: 'T',
    },
    {
      name: 'Friday',
      short: 'F',
    },
    {
      name: 'Saturday',
      short: 'S',
      isWeekend: true,
    },
    {
      name: 'Sunday', // used for accessibility 
      short: 'S', // displayed at the top of days' rows
      isWeekend: true, // is it a formal weekend or not?
    }
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 6,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },

  // texts in the date picker
  nextMonth: 'Next Month',
  previousMonth: 'Previous Month',
  openMonthSelector: 'Open Month Selector',
  openYearSelector: 'Open Year Selector',
  closeMonthSelector: 'Close Month Selector',
  closeYearSelector: 'Close Year Selector',
  defaultPlaceholder: 'Select...',

  // for input range value
  from: 'from',
  to: 'to',


  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
}