/*Common reusable component for SSP - Special Program Notification */

import Alert from 'react-bootstrap/Alert';
import { LINKS } from '../../constants';

export default function SSPAlert(props) {
    return (
        <div className="text-left">
            <Alert variant="warning" >
                <div className="d-flex flex-row align-items-start">
                    <p className="OpenSansSemiBold size-18 color-000 mb-0">
                        <span>You have selected SSP - Special Program.</span>
                    </p>
                </div>
                <p className="text-left">
                    The application must incorporate a period of annual leave within the proposed SSP period in accordance with the <a target="_blank" rel="noreferrer" href={LINKS.SPECIAL_STUDY_2015} >Special Studies Program Policy 2015</a> (#clause 14).
                </p>
            </Alert>
        </div>
    )
}