import axios, { AxiosResponse } from 'axios';
enum HTTP_STATUS {
    SUCCESS = 200,
    BAD_REQUEST = 400,
    NOT_FOUND = 404,
    FORBIDDEN = 403,
    SERVER_ERROR = 500,
    UNAUTHORIZED = 401,
}

export function onResponseError(error: any) {
    if (error.response?.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error.response);
}

export function onResponse(response: AxiosResponse) {
    if (response.status === HTTP_STATUS.SUCCESS) {
        return Promise.resolve(response.data);
    } else {
        return onResponseError(response);
    }
}