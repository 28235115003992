/*Common reusable component for Other leave notification */

import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { LINKS } from '../../constants';
import WarningIcon from '../../public/assets/warning-icon.svg';

export default function OtherLeave(props) {
    const { otherLeaveNotification } = props;
    return (
        <div>
            <Alert variant="warning" >
                <div className="d-flex flex-row align-items-start">
                    <p className="OpenSansSemiBold  size-18 color-000 mb-0">
                        <span className="pe-3"><img src={WarningIcon} alt="success icon" /></span>
                        <span>The traveller's {otherLeaveNotification} leave will not be booked automatically</span>
                    </p>
                </div>
            </Alert>
           <p>Some days in this travel diary have <b>{otherLeaveNotification}</b> as leave type. You must ensure that the traveller books the appropriate leave requests on <a target="_blank" rel="noreferrer" href={LINKS.WORKDAY_LINK}>Workday</a>, as this will not be done automatically.</p>
        </div>
    )
}