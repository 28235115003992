
export const DATE_FORMAT = 'MM/DD/YYYY';

export const BASE_URL = process.env.REACT_APP_BACKEND_URL; 

export const OKTA_CONFIG = {
    CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID,
    ISSUER: 'https://sso.sydney.edu.au/oauth2/default'
}

export const APP_URL = {
    SUBMISSIONS: 'submissions',
    AUTHENTICATION: 'authentication'
}

export const LINKS = {
    HOW_TO_GUIDE: "https://sydneyuni.service-now.com/sm?id=kb_article_view&amp;sysparm_article=KB0021019",
    TRAVEL_POLICIES_AND_PROCEDURES: "https://intranet.sydney.edu.au/services/travel/travel-policy-and-procedures.html",
    WORKDAY_LINK: 'https://sydney.edu.au/workday',
    FRINGE_BENEFIT_LINK: "https://www.sydney.edu.au/policies/showdoc.aspx?recnum=PDOC2011/136&RendNum=0",
    SPECIAL_STUDY_2015: "https://www.sydney.edu.au/policies/showdoc.aspx?recnum=PDOC2011/130&RendNum=0"
}

export const TABLE_MESSAGES = {
    LOADING: "Fetching Travel Records...",
    NO_RECORDS: "No records to display"
}

export const TOOLTIP_MESSAGES = {
    DATE_RANGE: "Trip starts when the traveller leaves home/work and ends when they arrive back at home/work",
    DOMESTIC_INTERNATIONAL_HEADING: 'Domestic | International Travel',
    DOMESTIC_DESCRIPTION: 'Domestic travel means travelling only within Australia',
    INTERNATIONAL_DESCRIPTION: 'International travel involves visiting a different country',
    LOCATION_DESCRIPTION: "If the traveller visits multiple location on a single day (or daterange), choose the one where the traveller will be spending most of their time.",
    BUSINESS_DAY_HEADING: 'Business Day',
    BUSINESS_DAY_DESCRIPTION: 'The work related days of trip (e.g. Meetings)',
    PRIVATE_DAY_HEADING: 'Private Day',
    PRIVATE_DAY_DESCRIPTION: "The private days of the trip (e.g. going on holiday).",
    REST_DAY_HEADING: 'Rest day',
    REST_DAY_DESCRIPTION: 'Eligible when travelling internationally in economy class for over 8 hours.',
    TRAVEL_DAY_HEADING: 'Travel day',
    TRAVEL_DAY_DESCRIPTION: "Eligible when the majority of work hours are spent travelling for work-related reasons.",
    STAFF_HEADING: "Staff",
    STAFF_DESCRIPTION: "Anyone who is employed by the University of Sydney (full-time, part-time or casual)",
    STUDENT_HEADING: "Student",
    STUDENT_DESCRIPTION: "Anyone who is enrolled at the University of Sydney. Includes HDR students.",
    GUEST_HEADING: 'Guest',
    GUEST_DESCRIPTION: "Anyone who holds an honorary position, or is a volunteer, contractor, consultant, etc.",
    PURPOSE_HEADING:"Purpose",
    PURPOSE_DESCRIPTION:'Overall nature of your activity',
    BUSINESS_WEEKEND_HEADING: 'Business Weekends',
    BUSINESS_WEEKEND_DESCRIPTION_1: 'Weekends between a non-Private Monday and a non-Private Friday will be considered Business.',
    BUSINESS_WEEKEND_DESCRIPTION_2: 'This is done to assess whether fringe benefits tax (FBT) applies to this trip.',
    PUBLIC_HOLIDAYS_HEADING: 'Public Holidays',
    PUBLIC_HOLIDAYS_DESCRIPTION: 'All Public Holidays will be considered Business.',
}

export const ERROR_MESSAGE = {
    ADD_DETAILS:'Failed to add travel details.Please enter all details correctly',
    SAVE_DETAILS: 'Failed to save travel details.Please enter all details correctly',
    UPDATE_DETAILS:"Failed to update travel details",
    REQUIRED_FIELDS:"Please fill all the required fields",
    DELETE_TRAVEL_DETAILS:'Failed to delete travel details',
    DOWNLOAD_PDF:'Failed to download pdf',
    TRAVELLER_UNIKEY:'Please fill the travel details',
    API_ERROR:"Failed to add travel details.",
    UNIKEY_ERROR: 'Please provide a valid UniKey',
    REST_DAY:"Rest day must follow travel day"
}
export const SUCCESS_MESSAGE={
    FORM_SAVED:'Form saved successfully',
    TRAVEL_DIARY_COMPLETED:"Your travel diary has been completed"
}
export const INFO_MESSAGE = {
    RETAIN_INTERNATIONAL: 'International Travel Details Restored',
    DOMESTIC_REMOVE: 'Removed Rest Days for Domestic Travel'
}