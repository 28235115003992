import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import {CustomLocale} from '../../utils/utilities';

function TripCalendar(props: any) {
  const [noOfCalendar, setNoofCalendar] = useState({});
  const monthEnteries = (array) => {
    let obj = {};
    array.forEach((elem, i) => { obj[`${i}_${elem[0]}`] = elem[1] });
    return obj
  }
  // Logic for grouping months so that as many calendars are dispalyed as selected
  const getDates = () => {
    let groupByMonth = groupByKey(props.calendarDates, 'month');
    const sortable = Object.entries(groupByMonth)
      .sort(([, a], [, b]) => a[0].year - b[0].year);
    let sortFinal = monthEnteries(sortable)
    setNoofCalendar(sortFinal);
  };
  
// group by key
  function groupByKey(array: any, key: any) {
    return array
      .reduce((hash: any, obj: any) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
      }, {})
  };

  useEffect(() => {
    if (props.calendarDates && props.calendarDates.length > 0 && props.calendarDates[0].hasOwnProperty('day')) {
      getDates();

    }
  }, [props.calendarDates]);// eslint-disable-line react-hooks/exhaustive-deps


  
  return (
    <>
      {
        Object.keys(noOfCalendar) && _.map(
          Object.keys(noOfCalendar),
          (key: string, index: number) => {
            let monthSplit = key.split('_');
            let month = monthSplit && monthSplit.length > 0 && monthSplit[monthSplit.length - 1];

              return(
                <div className="d-flex flex-row view-form-calendar-wrap">
                <div id={`calender-${month}`} className='mx-3 my-3 view-form-calendar w-100'>

                  <Calendar
                    locale={CustomLocale}

                    value={{
                      year: noOfCalendar[key] && noOfCalendar[key].length > 0 && noOfCalendar[key][0].year,
                      month: Number(month),
                      day: 1
                    }}
                    // onChange={(value)setSelectedDay}
                    shouldHighlightWeekends
                    customDaysClassName={props.calendarDates ?? []}
                  />
                </div>
                </div>
              )
          }
        )}
    </>
  )
}

export default TripCalendar;