
import React from 'react';
import DatePicker from "react-datepicker";
import PlusIcon from '../../public/assets/plus-ico.svg';
import ViewMoreIcon from '../../public/assets/view-more-ico.svg';
import DeleteIcon from '../../public/assets/delete-ico.svg';
import calendarIcon from '../../public/assets/ico-cal.svg';
import Select from "react-select";
import {  Draggable, Droppable } from "react-beautiful-dnd";

import styles from './tablescss/table.module.scss'
const grid = 20;
const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    ...draggableStyle
});
const getListStyle = (isDraggingOver: any) => ({
    background: "white",
    padding: grid,
});


export default function TableRows({ excludedDates, handleSEDChange, handleExcludedDate, handleExcludedDateOnBlur, dateRange, rowsData, addTableRows, deleteTableRows, handleChange, handleLocationChange, handlePurposeChange, handleactivityChange, handleDescriptionChange, handleCheckbox,onCalendarClose }: any, dateRangeFilled: boolean) {
    const eventHTML: any = [];

    rowsData.map((data: { id: number; Location?: any; locationValid?: boolean; SED?: any; Purpose?: any; purposeStateColor?: any; purposeList?: any; activityList?: any; Activity?: any; Description?: any; purposeStateArr: any, activityStateArr: any, SEDValid: boolean, PurposeValid: boolean, ActivityValid: boolean, DescriptionValid: boolean, checkBox: boolean, enableSED: boolean, enablePurpose: boolean, enableActivityDesc: boolean }, index: number) => {
        eventHTML.push(
            <>
                <Draggable
                    key={data.id}
                    draggableId={`${data.id}`}
                    index={index}>
                        
                    {(provider, snapshot) => (
                        <tr {...provider.draggableProps} ref={provider.innerRef} className={snapshot.isDragging ? "draggging" : ""} style={getItemStyle(
                            snapshot.isDragging,
                            provider.draggableProps.style
                        )}>
                            <td className='col-xs-1' {...provider.dragHandleProps} > <div className={`pe-3 ${styles['drag-icon']}`}/></td>

                            <td className='col-xs-1' style={{ display: "none" }}>
                                <input type="text" name="id" className="form-control" />
                            </td>

                            <td className='col-xs-1'>
                            <input
                                type="checkbox"
                                checked={data.checkBox}
                                onChange={(evnt) => (handleCheckbox(data.id, index, evnt))}
                            />
                            </td>

                            <td className='col-xs-1'>
                                <div className="label-float me-4">
                                    <input autoComplete="off" id="location" name="Location" value={data.Location} required={data.locationValid} onChange={(evnt) => (handleLocationChange(data.id, index, evnt))} className="w-100" type="text" placeholder=" " />
                                    {/* <label className="location">Location</label> */}
                                </div>
                            </td>

                            <td className='col-xs-1'>
                                <div className="label-float selectionDate">
                                    <div>
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            //readOnly={data.enableSED}
                                            showPopperArrow={false}
                                            selectsRange={true}
                                            startDate={data.SED[0]}
                                            endDate={data.SED[1]}
                                            className={`${styles[`req_${data.SEDValid}`]}  `}
                                            popperPlacement="bottom-start"
                                            showYearDropdown
                                            yearDropdownItemNumber={3}
                                            scrollableYearDropdown
                                            // excludeDateIntervals={data.SED}
                                            excludeDates={excludedDates}
                                            // filterDate = {selectedDates}
                                            monthsShown={2}
                                            minDate={dateRange[0]}
                                            maxDate={dateRange[1]}
                                            onCalendarClose={()=>onCalendarClose(data.id,index)}
                                            onChange={(update) => (handleSEDChange(data.id, update, index))}
                                            onFocus={(evnt) => (handleExcludedDate(data.id, evnt))}
                                            onBlur={(evnt) => (handleExcludedDateOnBlur(data.id, evnt))}
                                            calendarStartDay={1}
                                        />
                                        <span className="icon"><img src={calendarIcon} alt="Calendar icon" /></span>


                                    </div>
                                </div>
                            </td>

                            <td className="col-2 purpose-select purpose-dropdown-option className='col-xs-1'">
                                <Select
                                    // styles={colourStyles}
                                    id={`${styles[`disable_false`]}`}
                                    placeholder="Select purpose"
                                    value={data.purposeStateArr}
                                    //defaultValue={{ purpose: "Select Dept", value: 0 }}
                                    className={`${data.purposeStateColor}  ${styles[`req_${data.PurposeValid} `]} purposedropdown`}
                                    options={data.purposeList}
                                    onChange={(evnt) => (handlePurposeChange(data.id, index, evnt))}
                                    getOptionLabel={op => op?.purpose}
                                    getOptionValue={val => val?.purpose}
                                />
                            </td>

                            <td className="col-2 className='col-xs-1'">
                                <Select
                                    id={`${styles[`disable_false`]}`}
                                    placeholder="Choose Activity"
                                    value={data.activityStateArr}
                                    options={data.activityList}
                                    className={`${styles[`req_${data.ActivityValid}`]} leave-dropdown`}
                                    onChange={(evnt) => (handleactivityChange(data.id, index, evnt))}
                                    getOptionLabel={x => x?.Activityname}
                                    getOptionValue={x => x?.Activityname}
                                    isDisabled={(data.activityStateArr&&data.activityStateArr.length>0 && data.activityStateArr[0].Activityname==='N/A')||(data.activityStateArr.Activityname==='N/A')?true:false}
                                />
                            </td>

                            <td className="col-2 className='col-xs-1'">
                                {/* <input type="text" value={data.Description} onChange={(evnt) => (handleChange(data.id, evnt))} name="Description" className="form-control" required={data.DescriptionValid}/>  */}
                                <div className="label-float me-4">
                                    <textarea autoComplete="off" id={`${styles[`Description ${data.enableActivityDesc}`]}`} name="Description" value={data.Description} required={data.DescriptionValid && (data.purposeStateColor === 'Business')} onChange={(evnt) => (handleDescriptionChange(data.id, index, evnt))}
                                         />
                                    {/* <label className="Description">Description</label> */}
                                </div>
                            </td>


                            <td className='col-xs-1'>
                                {/* <span className={`${styles['view-more']}`}>
                                    <img src={ViewMoreIcon} alt="View More Icon" />
                                </span> */}
                                <span className={`${styles['show-btns']}`}>
                                    <span className="btn btn-sm  btn_row_below_clone" onClick={() => (addTableRows(index))} ><img src={PlusIcon} alt="Add Icon" /></span>
                                    <span className={`${styles.divider}`}>|</span>

                                    <span className={`btn btn-sm ${styles['btn_row_delete']}`} onClick={() => (deleteTableRows(index))}><img src={DeleteIcon} alt="Delete Icon" /></span>
                                </span>
                            </td>


                        </tr>
                    )}
                </Draggable>
            </>
        );
    return null
    });
    return (
        <>
            <Droppable droppableId="droppable-1">
                {(provider, snapshot) => (
                    <tbody
                        className="text-capitalize"
                        ref={provider.innerRef}
                        {...provider.droppableProps}
                        style={getListStyle(snapshot.isDraggingOver)}>
                        {eventHTML}


                        {provider.placeholder}
                    </tbody>
                )}
            </Droppable>
        </>
    )
}
