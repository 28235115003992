import * as React from 'react';
import styles from '../Footer/footer.module.scss'


interface IFooterProps {
}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  return (
    <footer className={` ${styles['footer-comp']} bg-DADADB px-5 d-flex flex-row py-4 align-items-center justify-content-end`}>
         <p className="color-414042 InterstateLight size-16 mb-0 pe-5">University of Sydney | Copyright @2022</p>
    </footer>   
  );
};

export default Footer;
