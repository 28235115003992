import React, { useEffect, useState } from 'react';
import TravelDetails from '../TravelDetails/TravelDetails';
import DefaultLayout from '../../routes/layout/Defaultlayout';

import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';

export function HomePage(props: any) {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [header, setHeader] = useState({})
  const [userClaims, setUserClaims] = useState({})

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  }

  // load user info and access token
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      }).catch((err) => {
        console.error(err);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  // get user name and unikey
  useEffect(() => {
    let userInfoDict = {}
    if(userInfo !== null){
      Object.entries(userInfo).forEach((claimEntry) => {
        const claimName = claimEntry[0];
        const claimValue = claimEntry[1];
        switch(claimName){
          case "name":
            userInfoDict['unikey'] = claimValue;
            break;
          case "given_name":
            userInfoDict['givenName'] = claimValue;
            break;
          case "family_name":
            userInfoDict['surName'] = claimValue;
            break;
        }
      });
      setUserClaims(userInfoDict);
    }
  }, [userInfo])

  // Set header
  useEffect(() => {
      // ACCESS TOKEN
      const accessOKEN = oktaAuth.getAccessToken()
      setHeader({
        headers: {
          Authorization: accessOKEN
        }
      })
  }, [userInfo, oktaAuth])

  if (!authState) {
    triggerLogin();
  } else {
    return (
      <DefaultLayout userData={userClaims}>
        <TravelDetails userData={userClaims} header={header}/>
      </DefaultLayout>
    );
  }
}

export default HomePage;


