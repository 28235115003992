import  React from 'react';
import styles from './subheader.module.scss'
import {getCurrentDate} from '../../utils/getCurrentDate'


export function SubHeader () {
    
  return (
         <div className={`${styles['form-header']}  bg-414042 px-4 pb-3 d-flex flex-row justify-content-between`}>
                <div className="d-flex flex-column ms-5 pt-4">
                    <p className="OpenSansBold color-fff size-24 mb-2">{getCurrentDate()}</p>
                </div>
        </div>
  );
}

export default SubHeader;

