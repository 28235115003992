import { API } from '../api/index';
import axios from 'axios'
import { APP_URL, BASE_URL } from '../constants';


// GET Submissions : Parameter uniKey,status
export const getTravelDetails = (unikey: string, config:object, status: string, cb: Function) => {
    return API.get(`${BASE_URL}/${APP_URL.SUBMISSIONS}/?unikey=${unikey}&status=${status}`, config)
        .then((response => {
            if (cb) cb(response);
        }))
        .catch(error => error)
}

export const getTravelDetailsByPage = (unikey: string, config:object, status: string, pageNum:number) => {

    return API.get(`${BASE_URL}/${APP_URL.SUBMISSIONS}/?unikey=${unikey}&status=${status}&page_num=${pageNum+1}`, config)
}


// POST Submissions
export const addTravelDetails = (payload: any, config:object,  cb: Function, onErrorCb: Function) => {
    return API.post(`${BASE_URL}/${APP_URL.SUBMISSIONS}/`, payload, config)
        .then((response => {
            if (cb) cb(response);
        }))
        .catch(error => {
            if (onErrorCb) onErrorCb(error)
            return error;
        })
}

// Update travel submission
export const updateTravelDetails = (id: string, payload: any, config:object, cb?: Function, onErrorcb?: Function) => {
    return axios.put(`${BASE_URL}/${APP_URL.SUBMISSIONS}/${id}`, payload, config)
        .then((response => {
            if (cb) cb(response.data);
        }))
        .catch(error => {
            if (onErrorcb) onErrorcb(error);
            return error;
        })

}

// Generate Pdf
export const generatePdf = (submission_id: string, config:object, cb?: Function,onError?:Function) => {
    const pdfConfig = {...config}
    pdfConfig['responseType']='blob'
    // { responseType: 'blob' }
    return API.post(`${BASE_URL}/${APP_URL.SUBMISSIONS}/${submission_id}/download`, {}, pdfConfig)
        .then((response => {
            if (cb) cb(response);
        }))
        .catch(error => {
            if (onError) cb(onError);
            return error;
        })
}

// Delete submission
export const deleteTravelDetails = (id: string, config:object, cb?: Function, onError?: Function) => {
    return axios.delete(`${BASE_URL}/${APP_URL.SUBMISSIONS}/${id}/`, config)
        .then((response => {
            if (cb) cb(response.data);
        }))
        .catch(error => {
            if (onError) cb(onError);
            return error;
        })

}