import { OverlayTrigger, Popover } from 'react-bootstrap';
import IconInfo from '../../public/assets/ico-info.svg';
import { TOOLTIP_MESSAGES } from '../../constants';

export default function RestTooltip() {
    return (
        <OverlayTrigger
            placement="top"
            trigger={["hover", "hover"]}
            overlay={
                <Popover>

                    <div id="popover-content" >
                        <div className="d-flex flex-column inner-popup-content px-3  py-3">
                            <p className="color-fff size-13 mb-0"><span className=" color-fff size-16 mb-0">{TOOLTIP_MESSAGES.REST_DAY_HEADING}: </span>{TOOLTIP_MESSAGES.REST_DAY_DESCRIPTION}</p>
                        </div>
                    </div>
                </Popover>
            }
        >
            <span className="ps-3" role="button" data-bs-toggle="popover" data-bs-trigger="hover" tabIndex={0} ><img className="locationImage" src={IconInfo} alt="Info icon" /></span>
        </OverlayTrigger>
    )
}