import { OverlayTrigger, Popover } from 'react-bootstrap';
import IconInfo from '../../public/assets/ico-info.svg';
import { TOOLTIP_MESSAGES } from '../../constants';

export default function StaffMemberTooltip() {
    return (
        <OverlayTrigger
                      placement="auto"
                      trigger={["hover", "hover"]}
                      overlay={
                        <Popover>
                          <div id="popover-content" >
                            <div className="d-flex flex-column inner-popup-content px-3 mb-0">
                              <div className="d-flex flex-column my-1 px-2 py-2 my-3 mb-0">
                                <h5 className="OpenSansSemiBold color-fff size-16 mb-0">{TOOLTIP_MESSAGES.STAFF_HEADING}</h5>
                                <p className="color-fff size-13 mb-0">{TOOLTIP_MESSAGES.STAFF_DESCRIPTION}</p>
                              </div>
                              <div className="d-flex flex-column my-1 px-2 py-2 my-3 mb-0">
                                <h5 className="OpenSansSemiBold color-fff size-16 mb-0">{TOOLTIP_MESSAGES.STUDENT_HEADING}</h5>
                                <p className="color-fff size-13 mb-0">{TOOLTIP_MESSAGES.STUDENT_DESCRIPTION}</p>
                              </div>
                              <div className="d-flex flex-column my-1 px-2 py-2 my-3 ">
                                <h5 className="OpenSansSemiBold color-fff size-16 mb-0">{TOOLTIP_MESSAGES.GUEST_HEADING}</h5>
                                <p className="color-fff  size-13 mb-0">{TOOLTIP_MESSAGES.GUEST_DESCRIPTION}</p>
                              </div>
                            </div>

                          </div>
                        </Popover>
                      }
                    >
                      <span className="ps-3 z-999" role="button" data-bs-toggle="popover" data-bs-trigger="hover" tabIndex={0} ><img className="locationImage" src={IconInfo} alt="Info icon" /></span>
                    </OverlayTrigger>
    )
}