import moment from "moment";

export function getCurrentDate(separator=' '){

    let newDate = moment().toDate();
    let date = newDate.getDate();
    let month = newDate.toLocaleString('en-us', { month: 'long' });
    let year = newDate.getFullYear();

    return `${date}${separator}${month}${separator}${year}`
}