import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    isCopy: false,
    isResume: false,
    copyPayload: {
    },
    resumePayload: {
    }
};
const settings = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setResumeTravelDetails(state, action) {
            state.isCopy = false;
            state.copyPayload = {};
            state.isResume  = action.payload.isResume;
            state.resumePayload = action.payload.details;
        },
        setCopyTravelDetails(state,action){
            state.isCopy = action.payload.isCopy;
            state.isResume  = false;
            state.resumePayload = {};
            state.copyPayload = action.payload.details
        }
    },
});
export const {  setResumeTravelDetails,setCopyTravelDetails } = settings.actions;
export default settings.reducer;
